import React from "react";
import PropTypes from "prop-types";
import * as amplitude from "@amplitude/analytics-browser";
import Img from "../../../../../components/Img/Img";
import HomePageSlider from "../HomePageSlider/HomePageSlider";
import Slide from "../HomePageSlider/Slide";
import CITIES from "./data";

const propTypes = {
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  analytics: PropTypes.object,
};

const Cities = ({ t, url, analytics }) => {
  const trackSlideClick = (cityName, position) => {
    amplitude.track("Homepage Slider Clicked", {
      destination_path: url,
      item_id: cityName,
      item_name: t(`regions.${cityName}`),
    });

    analytics.event(
      "HomePage",
      "home_click_carousel_cities",
      `${cityName}_${position}`,
    );
  };

  return (
    <HomePageSlider
      className="Cities"
      hasHover
      title={t("components.Cities.kicker")}
      subtitle={t("components.Cities.title")}
      slides={CITIES.map((city, index) => (
        <Slide
          url={url("listinglist", { region: city.slug })}
          alt={t("pages.ListingsPage.title", {
            region: t(`regions.${city.slug}`),
          })}
          src={city.src}
          title={t("pages.ListingsPage.title", {
            region: t(`regions.${city.slug}`),
          })}
          srcSet={city.coverImageSrcSet}
          caption={
            <div className="Cities-caption">
              <Img className="Cities-captionImage" src={city.coatOfArmsUrl} />
              <div className="Cities-captionText">
                <h2 className="Cities-cityName">{t(`regions.${city.slug}`)}</h2>
                <p className="Cities-cityDescription">
                  {t(`spages.homepage.citiesSection.tagline.${city.slug}`)}
                </p>
              </div>
            </div>
          }
          onClickSlide={() => {
            trackSlideClick(city.slug, index + 1);
          }}
        />
      ))}
    />
  );
};

Cities.propTypes = propTypes;

export default Cities;
