import React from "react";

const House3Icon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_5" data-name="Layer 5">
          <rect fill="none" width="20" height="20" />
          <path fill="#edf1f4" d="M5.65,18.17H.85V7.69h4.8Z" />
          <path fill="#dae0e6" d="M14.23,3.26v1H5.36l0-1,1-2.37h6.92Z" />
          <path fill="#dae0e6" d="M18.76,6.61v1H.87l0-1,1-1.37H17.79Z" />
          <path fill="#24272e" d="M3.91,9H2.35v2.33H3.91Z" />
          <path fill="#24272e" d="M3.91,11.73H2.35v.78H3.91Z" />
          <path fill="#24272e" d="M3.91,13.35H2.35v2.34H3.91Z" />
          <path fill="#24272e" d="M3.91,16.05H2.35v.78H3.91Z" />
          <path
            style={{
              isolation: "isolate",
              opacity: 0.1,
            }}
            d="M20,19.92H1.6L.41,18.72H19.25Z"
          />
          <path fill="#edf1f4" d="M18.77,18.17H14V7.69h4.79Z" />
          <path fill="#24272e" d="M17.21,9H15.66v2.33h1.55Z" />
          <path fill="#24272e" d="M17.21,11.73H15.66v.78h1.55Z" />
          <path fill="#24272e" d="M17.21,13.35H15.66v2.34h1.55Z" />
          <path fill="#24272e" d="M17.21,16.05H15.66v.78h1.55Z" />
          <path
            style={{
              isolation: "isolate",
              opacity: 0.1,
            }}
            d="M16.25,18.29H14.34V5.65h1l.87,1.62Z"
          />
          <path fill="#fff" d="M14,17.94H5.59V4H14Z" />
          <path
            fill="#24272e"
            d="M19.27,8.14h0v-1h0V6.45l-1.2-1.71h-3.3l0-1.48v0h0L13.6.39H6L4.84,3.16V4.74H1.6L.35,6.46V18.67H19.27Zm-1.74-2.4.73,1v.36H14.73V5.74ZM6.67,1.39h6.26l.74,1.84H5.9ZM2.11,5.74H4.84v1.4H1.37V6.77ZM1.36,17.67V8.18H4.84v9.49Zm11.87-.49H6.34V4.74h6.89V17.18Zm5,.49H14.73V8.18h3.54Z"
          />
          <path fill="#24272e" d="M9.36,6.27H7.69V8.79H9.36Z" />
          <path fill="#24272e" d="M9.36,9.15H7.69V10H9.36Z" />
          <path fill="#24272e" d="M11.88,6.27H10.2V8.79h1.68Z" />
          <path fill="#24272e" d="M11.88,9.15H10.2V10h1.68Z" />
          <path fill="#24272e" d="M9.36,11.66H7.69v2.52H9.36Z" />
          <path fill="#24272e" d="M9.36,14.6H7.69v.84H9.36Z" />
          <path fill="#24272e" d="M11.88,11.66H10.2v2.52h1.68Z" />
          <path fill="#24272e" d="M11.88,14.6H10.2v.84h1.68Z" />
        </g>
      </g>
    </svg>
  );
};

export default House3Icon;
