import React from "react";

const NeighborhoodIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect fill="none" width="20" height="20" />
          <polygon
            fill="#edf1f4"
            points="15.98 8.67 5.09 10.59 5.09 6.66 15.98 4.36 17.77 6.2 15.98 8.67"
          />
          <polygon
            fill="#edf1f4"
            points="4.32 14.32 15.21 16.24 15.21 12.08 4.32 10.01 2.53 11.85 4.32 14.32"
          />
          <path
            fill="#24272e"
            d="M16.32,9.22l2.23-3.08L16.18,3.71l-5,1v-1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V5.18l-4.73,1V9.44l-.38-.08L1.74,11.8,4,14.88l5.25.92v4h2V16.15l4.59.81V11.58l-4.59-.86v-.61Zm-1.71,3.36v2.95l-3.39-.6-2-.35-4.56-.8L3.31,11.91,4.49,10.7l0,0L6.25,11l3,.56,2,.38ZM9.22,9.26l-3,.52-.57-.11V7.15L9.22,6.4l2-.42,4.56-1L17,6.26,15.64,8.11l-4.42.79Z"
          />
          <polygon
            style={{ isolation: "isolate", opacity: "0.1" }}
            points="6.77 10.59 12.56 15.65 15.21 16.24 15.21 11.93 6.77 10.59"
          />
          <ellipse fill="#24272e" cx="5.75" cy="12.38" rx="0.66" ry="0.81" />
          <ellipse fill="#24272e" cx="14.74" cy="6.66" rx="0.66" ry="0.81" />
        </g>
      </g>
    </svg>
  );
};

export default NeighborhoodIcon;
