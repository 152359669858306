import React from "react";
import PropTypes from "prop-types";
import { useI18n } from "../../spages/spa/context/I18nContext";

const linksMap = {
  "ukraine-home": {
    DEFAULT: "https://www.helfendewaende.de/en",
    de: "https://www.helfendewaende.de",
    uk: "https://www.helfendewaende.de/ukr-2",
  },
  fsu: {
    DEFAULT: "https://wunderflats.com/page/services/property-rental-management",
    de: "https://wunderflats.com/page/leistungen/vermietungsmanagement",
  },
};

export const useLocalizedLink = ({ linkKey }) => {
  const { lang } = useI18n();

  if (!linksMap.hasOwnProperty(linkKey)) {
    throw new Error(`WebflowLink: linkKey "${linkKey}" is not defined`);
  }

  return linksMap[linkKey][lang] || linksMap[linkKey].DEFAULT;
};

const LocalizedLink = ({ linkKey, target = "_self", children, ...props }) => {
  const href = useLocalizedLink({ linkKey });

  return (
    <a target={target} href={href} {...props}>
      {children}
    </a>
  );
};

LocalizedLink.propTypes = {
  children: PropTypes.node.isRequired,
  linkKey: PropTypes.string.isRequired,
  target: PropTypes.oneOf(["_self", "_blank"]),
};

export default LocalizedLink;
