import React from "react";
import PropTypes from "prop-types";

const SearchIcon = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M15.45 12.522a3.892 3.892 0 000-5.55 4.006 4.006 0 00-5.618 0 3.892 3.892 0 000 5.55 4.007 4.007 0 005.618 0zm1.296-6.831a5.688 5.688 0 010 8.112c-2.169 2.143-5.627 2.235-7.907.278v.789l-3.803 3.757-1.383-1.366 3.803-3.757h.798a5.687 5.687 0 01.282-7.813c2.267-2.24 5.943-2.24 8.21 0z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

SearchIcon.propTypes = {
  className: PropTypes.string,
};

export default SearchIcon;
