import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./HomePageBanner.scss";
import SliderArrow from "../../../../../components/SliderArrow/SliderArrow";

const HomePageBanner = ({ colorVariant = "light", children, icon = null }) => {
  return (
    <div
      className={cn("HomePageBanner", {
        "HomePageBanner--light": colorVariant === "light",
        "HomePageBanner--dark": colorVariant === "dark",
      })}
    >
      <div className="HomePageBanner__wrapper">
        <div className="HomePageBanner__inner">
          <div className="HomePageBanner__icon">{icon}</div>
          <div>{children}</div>
        </div>
        <div className="HomePageBanner__arrow">
          <SliderArrow
            className="right HomePageBanner__arrow"
            fillColor={colorVariant === "light" ? "white" : "black"}
          />
        </div>
      </div>
    </div>
  );
};

HomePageBanner.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  colorVariant: PropTypes.oneOf(["light", "dark"]),
};

HomePageBanner.PrimaryText = ({ children }) => (
  <span className="HomePageBanner__PrimaryText">{children}</span>
);

HomePageBanner.PrimaryText.propTypes = {
  children: PropTypes.node.isRequired,
};

HomePageBanner.SecondaryText = ({ children }) => (
  <span className="HomePageBanner__SecondaryText">{children}</span>
);

HomePageBanner.SecondaryText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HomePageBanner;
