import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import wrapClamp from "wrap-clamp";
import dateUtils from "../../utils/date";
import d from "../../utils/dates";

const propTypes = {
  t: PropTypes.func.isRequired,
  selected: PropTypes.string,
  defaultMonth: PropTypes.string.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  onSelect: PropTypes.func,
  isDateAvailable: PropTypes.func,
  setAttributes: PropTypes.func,
  renderDay: PropTypes.func,
  onHoverDay: PropTypes.func,
};

const DateMonthView = ({
  t,
  selected,
  defaultMonth,
  minDate,
  maxDate,
  onSelect,
  isDateAvailable = (date, available) => available,
  setAttributes = (date, attrs) => attrs,
  renderDay,
  onHoverDay,
}) => {
  const [hovered, setHovered] = useState(null);

  const handleSelect = useCallback(
    (date, available) => {
      if (onSelect) {
        onSelect(date.format("YYYY-MM-DD"), available);
      }
    },
    [onSelect],
  );

  const handleHover = useCallback(
    (date) => {
      setHovered(date);
      if (onHoverDay) {
        onHoverDay(date);
      }
    },
    [onHoverDay],
  );

  const defaultMonthDayjs = d.dayjs(defaultMonth);
  const minDateDayjs = minDate && d.dayjs(minDate);
  const maxDateDayjs = maxDate && d.dayjs(maxDate);
  const selectedDayjs = selected ? d.dayjs(selected) : null;
  const today = d.dayjs();
  const firstDay = defaultMonthDayjs.clone().startOf("month");

  // dayjs week day: 0-sunday, 6-saturday
  // table week day: 0-monday, 6-sunday
  const firstWeekDay = wrapClamp(firstDay.day() - 1, 0, 6);

  const body = [0, 1, 2, 3, 4, 5].map((row) => {
    const cols = [0, 1, 2, 3, 4, 5, 6].map((weekDay) => {
      const offset = row * 7 + weekDay - firstWeekDay;
      const day = firstDay.clone().add(offset, "days");
      const monthOffset = day.clone().startOf("month").diff(firstDay, "months");
      const outOfBounds = !dateUtils.isAvailableBetween(
        minDateDayjs,
        day,
        maxDateDayjs,
      );
      const available = isDateAvailable(day.clone(), !outOfBounds);

      const attrs = setAttributes(
        day.clone(),
        {
          [day.format("YYYY-MM-DD")]: true,
          outOfBounds,
          previousMonth: monthOffset < 0,
          currentMonth: monthOffset === 0,
          nextMonth: monthOffset > 0,
          today: day.isSame(today, "day"),
          unavailable: !available,
          available,
          selected: selectedDayjs && day.isSame(selectedDayjs),
        },
        selectedDayjs ? selectedDayjs.clone() : null,
        hovered ? hovered.clone() : null,
        offset,
      );

      const constructDayClasses = () => {
        const classesObj = Object.entries(attrs).reduce((acc, [key, value]) => {
          acc[`DateMonthView-day--${key}`] = value;
          return acc;
        }, {});
        return classnames("DateMonthView-day", classesObj);
      };
      const props = {
        key: offset,
        onMouseOver: () => handleHover(day),
        onMouseOut: () => handleHover(null),
        onClick: () => handleSelect(day, available),
        className: constructDayClasses(),
      };

      return renderDay ? (
        renderDay(day, props, attrs)
      ) : (
        <td
          {...props}
          data-testid="DateMonthView-dateBtn"
          data-value={day.format("YYYY-MM-DD")}
        >
          {day.format("D")}
        </td>
      );
    });

    return <tr key={row}>{cols}</tr>;
  });

  return (
    <table className="DateMonthView">
      <tbody>
        <tr>
          <th>{t("components.DateMonthView.mo")}</th>
          <th>{t("components.DateMonthView.tu")}</th>
          <th>{t("components.DateMonthView.we")}</th>
          <th>{t("components.DateMonthView.th")}</th>
          <th>{t("components.DateMonthView.fr")}</th>
          <th>{t("components.DateMonthView.sa")}</th>
          <th>{t("components.DateMonthView.su")}</th>
        </tr>
        {body}
      </tbody>
    </table>
  );
};

DateMonthView.propTypes = propTypes;
export default DateMonthView;
