import React from "react";
import PropTypes from "prop-types";
import CardImageHeader from "../../../../../components/CardImageHeader/CardImageHeader";
import { useLocalizedLink } from "../../../../../components/LocalizedLink/LocalizedLink";
import { LANDLORD_INTENT_LOCATION } from "../../../../../tracker/constants";
import { landlordSectionURLs } from "../../../../../utils/GCPArtefacts/static-artefacts";
import { useTracker } from "../../../context/TrackerContext";

const LandlordSection = ({ t, url, lang = "en" }) => {
  const fsuHref = useLocalizedLink({ linkKey: "fsu" });
  const { tracker } = useTracker();

  return (
    <React.Fragment>
      <h2 className="HomePage-LandlordSection-title">
        {t("spages.homepage.landlordFSUSection.title")}
      </h2>
      <h3 className="HomePage-LandlordSection-subtitle">
        {t("spages.homepage.landlordFSUSection.subtitle")}
      </h3>
      <div className="HomePage-LandlordSection-CardContainer">
        <CardImageHeader
          img={{
            src: landlordSectionURLs.cardImageHeaderLLSrc,
            srcSet: landlordSectionURLs.landlordCardSrcSet,
            sizes:
              "(max-width: 999px) 100vw, (min-width: 1000px) calc((100vw - 160px) / 2), (min-width: 1448px) 630px",
            alt: "Modern living room",
          }}
          title={t("spages.homepage.becomeALandlord.title")}
          description={t("spages.homepage.becomeALandlord.description")}
          cta={{
            link: url("landlords"),
            text: t("spages.homepage.becomeALandlord.learnMore"),
          }}
          className="HomePage-LandlordSection-Card Purple"
          onCardClickEvent={() => {
            tracker.events.trackLandlordIntentClicked({
              triggerLocation: LANDLORD_INTENT_LOCATION.HOMEPAGE_CARD,
            });
          }}
        />
        <CardImageHeader
          img={{
            src: landlordSectionURLs.cardImageHeaderLLSrc,
            srcSet: landlordSectionURLs.fsuCardSrcSet,
            sizes:
              "100vw, (min-width: 1000px) calc((100vw - 160px) / 2), (min-width: 1448px) 630px",
            alt: "Two men in a garden",
          }}
          title={t("spages.homepage.fsu.title")}
          description={t("spages.homepage.fsu.description")}
          cta={{
            link: `${fsuHref}/?utm_source=website&utm_medium=referral&utm_campaign=website_supply_fsu_${lang}&utm_content=fsu_homepage_link_${lang}`,
            text: t("spages.homepage.fsu.learnMore"),
          }}
          className="HomePage-LandlordSection-Card Peach"
        />
      </div>
    </React.Fragment>
  );
};

LandlordSection.propTypes = {
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default LandlordSection;
