/* eslint-disable react/prop-types */
import "./GeocodingInputSearch.scss";

import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useI18n } from "../../spages/spa/context/I18nContext";
import {
  fetchSuggestions,
  getGeocodingItemLabel,
} from "../../utils/geocodingHelpers";
import InputAutocomplete from "../FormElements/InputAutocomplete/InputAutocomplete";
import CloseArrowIcon from "../Icons/CloseArrowIcon";
import SearchIcon from "../Icons/SearchIcon";
import GeocodingSuggestion from "./GeocodingSuggestion";
import getSuggestedCities from "./suggestedCitiesList";

const propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  suggestionBoxClassName: PropTypes.string,
  isTransparent: PropTypes.bool,
  insideDrawer: PropTypes.bool,
  showInputIcon: PropTypes.bool,
  onSelectSuggestion: PropTypes.func,
  placeholder: PropTypes.string,
  lang: PropTypes.string,
  bias: PropTypes.string,
};

export const searchIconColors = {
  transparent: "#ffffff",
  default: "#24272E",
};

function GeocodingInputSearch({
  lang,
  bias,
  className,
  suggestionBoxClassName,
  isTransparent,
  insideDrawer,
  showInputIcon,
  animateWidth,
  onSelectSuggestion,
  placeholder,
  variant,
  hideSuggestionArrow,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);

  const inputIcon = isFocused ? (
    <CloseArrowIcon
      fillPathColor={
        isTransparent ? searchIconColors.transparent : searchIconColors.default
      }
    />
  ) : (
    <SearchIcon
      fillPathColor={
        isTransparent ? searchIconColors.transparent : searchIconColors.default
      }
    />
  );

  const { t } = useI18n();

  const suggestedCities = useMemo(() => getSuggestedCities(t), [t]);
  const computedClassNames = useMemo(
    () =>
      classNames("GeocodingInputSearch", className, {
        "GeocodingInputSearch--isFocused": isFocused && animateWidth,
        "GeocodingInputSearch--isTransparent": isTransparent,
        "GeocodingInputSearch--light": variant === "light",
      }),
    [animateWidth, className, isFocused, isTransparent, variant],
  );

  const onFocus = useCallback(() => setIsFocused(true), [setIsFocused]);
  const onHiddenSuggestions = useCallback(
    () => setIsFocused(false),
    [setIsFocused],
  );

  const getSuggestionLabel = useCallback(
    (place) => (
      <GeocodingSuggestion
        key={place.id}
        place={place}
        hideSuggestionArrow={hideSuggestionArrow}
      />
    ),
    [hideSuggestionArrow],
  );

  const biasedFetchSuggestions = useCallback(
    (input) => fetchSuggestions({ ...input, bbox: bias }),
    [bias],
  );

  const FETCH_SUGGESTIONS_DELAY_MS = 500;
  const MIN_SEARCH_LENGTH = 3;

  return (
    <InputAutocomplete
      className={computedClassNames}
      debounceTime={FETCH_SUGGESTIONS_DELAY_MS}
      minLengthForSearch={MIN_SEARCH_LENGTH}
      suggestionBoxClassName={suggestionBoxClassName}
      emptyStateSuggestions={suggestedCities}
      data-testid="GeocodingInputSearchId"
      onFocus={onFocus}
      onHiddenSuggestions={onHiddenSuggestions}
      fetchSuggestions={biasedFetchSuggestions}
      getSuggestionLabel={getSuggestionLabel}
      getSuggestionTextLabel={getGeocodingItemLabel}
      icon={showInputIcon ? inputIcon : undefined}
      onSelectSuggestion={onSelectSuggestion}
      placeholder={placeholder}
      selectFirstOnEnter
      restorePreviousValueOnBlur
      {...props}
    />
  );
}

GeocodingInputSearch.propTypes = propTypes;

export default GeocodingInputSearch;
