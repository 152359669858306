export default function getCitiesList(t) {
  return [
    {
      id: "place.14094307404564380",
      text: t("cities.berlin.name"),
      textEn: "Berlin",
      context: t("countries.DE"),
      contextEn: "Germany",
      region: "berlin",
      placeType: ["place"],
    },
    {
      id: "place.14749210607497330",
      text: t("cities.paris.name"),
      textEn: "Paris",
      context: t("countries.FR"),
      contextEn: "Germany",
      region: "paris",
      placeType: ["place"],
    },
    {
      id: "place.14471293545398860",
      text: t("cities.hamburg.name"),
      textEn: "Hamburg",
      context: t("countries.DE"),
      contextEn: "Germany",
      region: "hamburg",
      placeType: ["place"],
    },
    {
      id: "place.8366109811859780",
      text: t("cities.munich.name"),
      textEn: "Munich",
      context: t("countries.DE"),
      contextEn: "Germany",
      region: "munich",
      placeType: ["place"],
    },
    {
      id: "place.12852876792082660",
      text: t("cities.dusseldorf.name"),
      textEn: "Dusseldorf",
      context: t("countries.DE"),
      contextEn: "Germany",
      region: "dusseldorf",
      placeType: ["place"],
    },
    {
      id: "place.13498115301191980",
      text: t("cities.cologne.name"),
      textEn: "Cologne",
      context: t("countries.DE"),
      contextEn: "Germany",
      region: "cologne",
      placeType: ["place"],
    },
    {
      id: "place.7315137962190980",
      text: t("cities.frankfurt.name"),
      textEn: "Frankfurt",
      context: t("countries.DE"),
      contextEn: "Germany",
      region: "frankfurt",
      placeType: ["place"],
    },
  ];
}
