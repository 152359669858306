import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  fillPathColor: PropTypes.string,
};

function SearchIcon({ fillPathColor = "#24272E", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M9.338 7.142a2.919 2.919 0 000-4.163 3.005 3.005 0 00-4.214 0 2.919 2.919 0 000 4.163 3.005 3.005 0 004.214 0zm.972-5.124a4.266 4.266 0 010 6.084 4.393 4.393 0 01-5.93.21v.59L1.526 11.72.49 10.696l2.852-2.818h.598a4.265 4.265 0 01.212-5.86 4.392 4.392 0 016.158 0z"
        clipRule="evenodd"
        fill={fillPathColor}
      />
    </svg>
  );
}

SearchIcon.propTypes = propTypes;

export default SearchIcon;
