import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  svgTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.oneOf(["eager", "lazy"]),
};

const Caption = ({ svgTitle, subtitle, title, loading = "lazy" }) => {
  return (
    <div className="Caption">
      <img
        src={svgTitle}
        loading={loading}
        className="Caption-title"
        alt={title}
      />
      <span className="Caption-subtitle">{subtitle}</span>
    </div>
  );
};

Caption.propTypes = propTypes;

export default Caption;
