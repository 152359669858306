import React from "react";

const House4Icon = (props) => {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g data-name="Layer 2" id="Layer_2">
        <g data-name="Layer 5" id="Layer_5">
          <rect fill="none" height="20" width="20" />
          <path fill="#dae0e6" d="M12.15,3.26l0,1h-11v-1l1-2.37h9Z" />
          <path fill="#dae0e6" d="M18.76,6.61v1H7.5l0-1,1-1.37h9.31Z" />
          <path
            style={{
              isolation: "isolate",
              opacity: 0.1,
            }}
            d="M20,19.92H2.14L.68,18.72H19.24Z"
          />
          <path fill="#edf1f4" d="M18.77,18.17H12.11V7.69h6.66Z" />
          <path
            style={{
              isolation: "isolate",
              opacity: 0.1,
            }}
            d="M14.34,18.05H12.42V5.41h1L14.34,7Z"
          />
          <path fill="#fff" d="M11.87,17.94H1.39V4H11.87Z" />
          <path
            fill="#24272e"
            d="M19.28,8.14V7.2h0V6.44l-1.2-1.71H12.68l0-1.48L11.52.39H1.8L.64,3.16v.06h0V18.68H19.27V8.14ZM17.53,5.73l.73,1v.37H12.62V5.73ZM2.47,1.39h8.38l.74,1.83H1.7Zm8.65,15.79h-9V4.72h9V17.18Zm7.15.5H12.62V8.2h5.65Z"
          />
          <path
            fill="#24272e"
            d="M4,13h.08a.71.71,0,0,1,.71.71v2.09a0,0,0,0,1,0,0H3.27a0,0,0,0,1,0,0V13.69A.71.71,0,0,1,4,13Z"
          />
          <path
            fill="#24272e"
            d="M6.52,13H6.6a.71.71,0,0,1,.71.71v2.09a0,0,0,0,1,0,0H5.81a0,0,0,0,1,0,0V13.69A.71.71,0,0,1,6.52,13Z"
          />
          <path
            fill="#24272e"
            d="M9.06,13h.08a.71.71,0,0,1,.71.71v2.09a0,0,0,0,1,0,0H8.35a0,0,0,0,1,0,0V13.69A.71.71,0,0,1,9.06,13Z"
          />
          <path
            fill="#24272e"
            d="M4,9.55h.08a.71.71,0,0,1,.71.71v2.09a0,0,0,0,1,0,0H3.27a0,0,0,0,1,0,0V10.26A.71.71,0,0,1,4,9.55Z"
          />
          <path
            fill="#24272e"
            d="M6.52,9.55H6.6a.71.71,0,0,1,.71.71v2.09a0,0,0,0,1,0,0H5.81a0,0,0,0,1,0,0V10.26a.71.71,0,0,1,.71-.71Z"
          />
          <path
            fill="#24272e"
            d="M9.06,9.55h.08a.71.71,0,0,1,.71.71v2.09a0,0,0,0,1,0,0H8.35a0,0,0,0,1,0,0V10.26A.71.71,0,0,1,9.06,9.55Z"
          />
          <path
            fill="#24272e"
            d="M4,6.13h.08a.71.71,0,0,1,.71.71V8.93a0,0,0,0,1,0,0H3.27a0,0,0,0,1,0,0V6.84A.71.71,0,0,1,4,6.13Z"
          />
          <path
            fill="#24272e"
            d="M6.52,6.13H6.6a.71.71,0,0,1,.71.71V8.93a0,0,0,0,1,0,0H5.81a0,0,0,0,1,0,0V6.84A.71.71,0,0,1,6.52,6.13Z"
          />
          <path
            fill="#24272e"
            d="M9.06,6.13h.08a.71.71,0,0,1,.71.71V8.93a0,0,0,0,1,0,0H8.35a0,0,0,0,1,0,0V6.84A.71.71,0,0,1,9.06,6.13Z"
          />
          <path
            fill="#24272e"
            d="M14.41,13.29h0a.68.68,0,0,1,.68.68v2.12a0,0,0,0,1,0,0H13.73a0,0,0,0,1,0,0V14A.68.68,0,0,1,14.41,13.29Z"
          />
          <path
            fill="#24272e"
            d="M16.45,13.29h0a.68.68,0,0,1,.68.68v2.12a0,0,0,0,1,0,0H15.77a0,0,0,0,1,0,0V14a.68.68,0,0,1,.68-.68Z"
          />
          <path
            fill="#24272e"
            d="M14.41,9.87h0a.68.68,0,0,1,.68.68v2.12a0,0,0,0,1,0,0H13.73a0,0,0,0,1,0,0V10.55A.68.68,0,0,1,14.41,9.87Z"
          />
          <path
            fill="#24272e"
            d="M16.45,9.87h0a.68.68,0,0,1,.68.68v2.12a0,0,0,0,1,0,0H15.77a0,0,0,0,1,0,0V10.55A.68.68,0,0,1,16.45,9.87Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default House4Icon;
