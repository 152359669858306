import cookieCutter from "js-cookie";

export const zipCodes = [
  "1010",
  "1020",
  "1030",
  "1040",
  "1050",
  "1060",
  "1070",
  "1080",
  "1090",
  "1100",
  "1110",
  "1120",
  "1130",
  "1140",
  "1150",
  "1160",
  "1170",
  "1180",
  "1190",
  "1200",
  "1210",
  "1220",
  "1230",
  "2100",
  "2281",
];

const VIENNA_DURATION_MESSAGE_COOKIE = "dismissViennaDurationMessage";

export const isViennaListing = ({ country, zipCode }) =>
  country === "AT" && zipCodes.includes(zipCode);

export const shouldDisplayDurationMessage = ({ cookies = {} }) =>
  !cookies[VIENNA_DURATION_MESSAGE_COOKIE];

export const dismissDurationMessage = () => {
  cookieCutter.set(VIENNA_DURATION_MESSAGE_COOKIE, "true", {
    path: "/",
    expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // in one year
  });
};
