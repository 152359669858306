import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Img from "../Img/Img";

const propTypes = {
  src: PropTypes.string.isRequired,
  sizes: PropTypes.string,
  srcSet: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  imageClassName: PropTypes.string,
  attributes: PropTypes.array,
  loading: PropTypes.oneOf(["eager", "lazy"]),
  imgRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(React.Component) }),
  ]),
};

const CoverImage = ({
  imgRef,
  children,
  className,
  src,
  alt = "",
  style = {},
  srcSet,
  sizes,
  imageClassName,
  loading = "lazy",
  ...attributes
}) => {
  return (
    <figure
      {...attributes}
      style={style}
      className={`CoverImage${className ? ` ${className}` : ""}`}
    >
      <Img
        ref={imgRef}
        className={classnames(imageClassName, "CoverImage", "CoverImage-image")}
        alt={alt}
        loading={loading}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
      />
      {children}
    </figure>
  );
};

CoverImage.propTypes = propTypes;

export default CoverImage;
