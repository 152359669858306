/**
 * src/components/FormElements/InputAutocomplete/InputAutocomplete.js
 *
 * Example – using Google Places API:
 *
 *    const fetchSuggestions = async ({ text }) => {
 *      return ({ suggestions: await addressAutocomplete.getAddressSuggestions(text) });
 *    };
 *
 *    // Optional:
 *    const getMatchedSubstrings = (suggestion) => {
 *      return suggestion.matched_substrings;
 *    };
 *
 *    // Optional:
 *    const getSuggestionLabel = (suggestion) => {
 *      // Defaults to simply `suggestion` if `suggestion` is a `string`.
 *      // Otherwise defaults to `suggestion.label`.
 *      return suggestion.description;
 *    };
 *
 *    return (
 *      <InputAutocomplete
 *        className={classNames("InputAddress", className)}
 *        fetchSuggestions={fetchSuggestions}
 *        getSuggestionLabel={getSuggestionLabel}
 *        getMatchedSubstrings={getMatchedSubstrings}
 *      />
 *    );
 *
 * Accessibility features were copied from https://haltersweb.github.io/Accessibility/autocomplete.html.
 */

import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { useUIDSeed } from "react-uid";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import { isChild } from "../../../utils/dom";
import SvgSpinner from "../../SvgSpinner/SvgSpinner";
import InputText from "../InputText/InputText";
import TextWithMatchedSubstrings from "./TextWithMatchedSubstrings";
import "./InputAutocomplete.scss";

const propTypes = {
  // fetchSuggestions is an async function that returns an array of results. These results can
  // be strings, objects or anything else.
  fetchSuggestions: PropTypes.func.isRequired,

  // If you want to debounce the fetch, you can set the time in ms here, this will actually debounce
  // changing the input "value" while the user is typing and leaves the fetchSuggestions untouched
  debounceTime: PropTypes.number,

  // Start to fetch suggestions starting from a minimum length of text
  minLengthForSearch: PropTypes.number,

  // emptyStateSuggestions is an array of suggestions you want to show when the user focused on the element but didn't start typing yet.
  emptyStateSuggestions: PropTypes.array,

  // getMatchedSubstrings returns an array of { offset: Number, length: Number } objects.
  // These are used to highlight partial matches in the results-list.
  // See also TextWithMatchedSubstrings.propTypes.matchedSubstrings.
  getMatchedSubstrings: PropTypes.func,

  // getSuggestionLabel returns the string that should be used to render the suggestion
  // in the results-list. The returned string is also used to highlight partial matches
  // (see getMatchedSubstrings).
  // getSuggestionLabel can also return a JSX node, in this case, use also getSuggestionTextLabel
  getSuggestionLabel: PropTypes.func,

  // getSuggestionTextLabel returns the string that should be used to render the selected suggestion in the input
  getSuggestionTextLabel: PropTypes.func,

  // renderSuggestionsFooter is a render-function that allows you to, for example,
  // render the logo of the provider of the autocomplete data
  renderSuggestionsFooter: PropTypes.func,

  // onSelectSuggestion will be called when the user confirms a suggestion
  onSelectSuggestion: PropTypes.func,

  // loading: Show a loading spinner. Useful when loading data related to the
  // autocomplete input.
  loading: PropTypes.bool,

  // value is the value of the underlying textinput
  value: PropTypes.string,

  // onChange gets called for every keystroke or action that leads to the
  // value of the textinput changing. If you're looking to get notified
  // when the user selects a suggestion via keyboard or mouse, use onSelectSuggestion.
  onChange: PropTypes.func,

  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  error: PropTypes.any,
  className: PropTypes.string,
  suggestionBoxClassName: PropTypes.string,

  // icon: If you pass an Icon it'll be displayed at the very right of the InputText
  icon: PropTypes.element,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  selectFirstOnEnter: PropTypes.bool,
  restorePreviousValueOnBlur: PropTypes.bool,
  onHiddenSuggestions: PropTypes.func,
  keepSuggestions: PropTypes.bool,
};

// fetchSuggestions version tracker
// We place this here because updating the state is an asynchronous operation and
// we don't need to update the React UI based on this value
const versionTracker = {
  version: 0,
  data: [],
  loadingNewVersion: false,
};

function InputAutocomplete({
  className,
  suggestionBoxClassName,
  value = "",
  fetchSuggestions,
  debounceTime,
  minLengthForSearch,
  emptyStateSuggestions,
  // eslint-disable-next-line no-unused-vars
  getMatchedSubstrings = (suggestion) => [],
  getSuggestionTextLabel,
  getSuggestionLabel = (suggestion) => {
    return typeof suggestion === "string"
      ? suggestion
      : suggestion?.label || "";
  },
  renderSuggestionsFooter,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  onSelectSuggestion,
  onHiddenSuggestions,
  loading = false,
  error,
  icon,
  inputRef = createRef(),
  selectFirstOnEnter = false,
  restorePreviousValueOnBlur,
  keepSuggestions = false,
  ...props
}) {
  const { t, lang } = useI18n();

  const getLabel = getSuggestionTextLabel || getSuggestionLabel;

  // Keep track of the last error that occured while fetching suggestions
  const [fetchSuggestionsError, setFetchSuggestionsError] = useState(false);

  // Keep track of focus-state so we can style the color of the LoadingSpinner
  // depending on that
  const [isFocused, setIsFocused] = useState(false);

  // The array of suggestions
  const [suggestions, setSuggestions] = useState([]);

  // Keep track of how many suggestion-fetches are currently in progress. Used to
  // display a loading state.
  const [numInFlightSuggestions, setNumInFlightSuggestions] = useState(0);

  // We'll show a loading state if we have suggestions loading for more than X amount of time
  const [showSuggestionsLoading, setShowSuggestionsLoading] = useState(false);

  // Keeping `showSuggestions` in the state allows us to programmatically show and hide
  // the suggestionsBox
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Keeping the value of the underlying textinput in the state allows this component to
  // be uncontrolled if desired.
  const [_value, _setValue] = useState(value);

  // It's the same as _value, in case there is no debounce.
  // It's slower to update if there is a debounce function.
  const [_valueToSearch, _setValueToSearch] = useState(value);

  // The index of the suggestion that is currently focused. Used for keyboard navigation.
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(-1); // -1 means nothing is focused

  // A ref to the domNode of the container. Allows us to determine whether certain click-events
  // happen inside or outside of this component.
  const containerRef = useRef(null);

  // A ref to the container of the suggestions. Used to calculate the scrolling of the suggestions when user is using the keyboard
  const suggestionsContainerRef = useRef();

  // A ref for every suggestion. Used to calculate the scrolling of the suggestions when user is using the keyboard
  const suggestionsRefsArray = React.useRef([]);

  const getId = useUIDSeed();

  const scrollSuggestionIntoView = useCallback(
    (suggestionIndex) => {
      const parentElement = suggestionsContainerRef.current;
      const childElement = suggestionsRefsArray.current[suggestionIndex];
      if (!parentElement || !childElement) return;

      const parentClientRect = parentElement.getBoundingClientRect();
      const parentBottom = parentClientRect.bottom;
      const parentTop = parentClientRect.top;

      const childRect = childElement.getBoundingClientRect();
      const childBottom = childRect.bottom;
      const childTop = childRect.top;

      if (childBottom > parentBottom) {
        parentElement.scrollTop += childBottom - parentBottom;
      } else if (childTop < parentTop) {
        parentElement.scrollTop -= parentTop - childTop;
      }
    },
    [suggestionsContainerRef, suggestionsRefsArray],
  );

  const debouncedUpdateValueToSearch = useMemo(() => {
    const debounced = debounceTime
      ? debounce(_setValueToSearch, debounceTime, {
          leading: true,
          trailing: true,
        })
      : _setValueToSearch;

    return minLengthForSearch
      ? (text) => {
          if (!!text && text?.length < minLengthForSearch) return;
          return debounced(text);
        }
      : debounced;
  }, [debounceTime, minLengthForSearch]);

  useEffect(() => {
    // Fetch suggestions when value changes
    if (!_valueToSearch) {
      setSuggestions(emptyStateSuggestions || []);
      return;
    }

    if (!showSuggestions) {
      return;
    }

    const newVersion = versionTracker.version + 1;
    versionTracker.loadingNewVersion = true;

    (async () => {
      try {
        setNumInFlightSuggestions((num) => num + 1);
        setFetchSuggestionsError(null);
        const data = await fetchSuggestions({
          text: _valueToSearch,
          lang,
          version: newVersion,
        });
        versionTracker.loadingNewVersion = false;
        if (
          !versionTracker.version ||
          !data.version ||
          data.version > versionTracker.version
        ) {
          setSuggestions(data.suggestions);
          versionTracker.data = data.suggestions;
          versionTracker.version = newVersion;
        }
      } catch (err) {
        console.error("Error fetching autocomplete suggestions");
        console.error(err);
        setFetchSuggestionsError(err);
      }
      setNumInFlightSuggestions((num) => num - 1);
    })();
  }, [
    _valueToSearch,
    emptyStateSuggestions,
    fetchSuggestions,
    lang,
    showSuggestions,
  ]);

  useEffect(() => {
    // Show a loading indicator if suggestions have been loading for
    // longer than X amount of time
    if (numInFlightSuggestions > 0) {
      const timerId = setTimeout(() => {
        setShowSuggestionsLoading(true);
      }, 500);

      return () => {
        clearTimeout(timerId);
      };
    }

    setShowSuggestionsLoading(false);
  }, [numInFlightSuggestions]);

  // If the value-prop changes, display it in the textinput
  useEffect(() => {
    if (onChange && value !== _value) {
      _setValue(value);
      setFocusedSuggestionIndex(-1);
      debouncedUpdateValueToSearch(value);
    }
  }, [_value, value, onChange, debouncedUpdateValueToSearch]);

  const _onChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }

    setShowSuggestions(true);
    setFocusedSuggestionIndex(-1);
    _setValue(e.target.value);
    debouncedUpdateValueToSearch(e.target.value);
  };

  const _onFocus = (e) => {
    if (onFocus) {
      onFocus(e);
    }

    setIsFocused(true);
    setShowSuggestions(true);
  };

  const _onHiddenSuggestions = useCallback(() => {
    if (onHiddenSuggestions) {
      onHiddenSuggestions();
    }

    setShowSuggestions(false);
  }, [onHiddenSuggestions]);

  useEffect(() => {
    const isFocusedOnChild = isChild(
      containerRef.current,
      document.activeElement,
    );
    const isFocusedOutsideBrowser = !document.hasFocus();
    if (
      !isFocused &&
      (!isFocusedOnChild || isFocusedOutsideBrowser) &&
      !keepSuggestions
    ) {
      _onHiddenSuggestions();
    }
  }, [isFocused, _onHiddenSuggestions, keepSuggestions]);

  const _onBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }

    setTimeout(() => {
      // If the input is empty on blur, restore submitted value
      if (restorePreviousValueOnBlur && e.target.value === "") {
        _setValue(value);
      }
    });
    setIsFocused(false);
  };

  const _onSelectSuggestion = (suggestion) => {
    if (onSelectSuggestion) {
      onSelectSuggestion(suggestion);
    } else if (onChange) {
      onChange(getLabel(suggestion));
    }

    setFocusedSuggestionIndex(-1);
    _onHiddenSuggestions();
    _setValue(getLabel(suggestion));
    inputRef.current?.blur();
  };

  const _selectSuggestionOnEnter = () => {
    // The user might press enter before the response from the fetch suggestions arrived.
    // so we need to wait for the last version of the suggestions, before selecting one.
    if (_value !== _valueToSearch || versionTracker.loadingNewVersion) {
      return setTimeout(_selectSuggestionOnEnter, 200);
    }
    // We don't know if the suggestions in the state is updated already,
    // since setState is an asynchronous operation,
    // so we use the versionTracker data for this.
    const suggestion =
      selectFirstOnEnter && focusedSuggestionIndex === -1
        ? versionTracker.data[0]
        : versionTracker.data[focusedSuggestionIndex];
    return _onSelectSuggestion(suggestion);
  };

  const _onKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
    switch (e.key) {
      case "Down": // IE/Edge specific value
      case "ArrowDown": {
        e.preventDefault();

        const nextFocusedSuggestionIndex =
          focusedSuggestionIndex >= suggestions.length - 1
            ? -1
            : focusedSuggestionIndex + 1;

        setFocusedSuggestionIndex(nextFocusedSuggestionIndex);

        scrollSuggestionIntoView(nextFocusedSuggestionIndex);

        break;
      }
      case "Up": // IE/Edge specific value
      case "ArrowUp": {
        e.preventDefault();

        let nextFocusedSuggestionIndex = focusedSuggestionIndex - 1;

        if (focusedSuggestionIndex === 0) {
          nextFocusedSuggestionIndex = -1;
        } else if (focusedSuggestionIndex === -1) {
          nextFocusedSuggestionIndex = suggestions.length - 1;
        }

        setFocusedSuggestionIndex(nextFocusedSuggestionIndex);

        scrollSuggestionIntoView(nextFocusedSuggestionIndex);

        break;
      }
      case "Tab": {
        if (focusedSuggestionIndex !== -1) {
          // Don't preventDefault() for this one
          const suggestion = suggestions[focusedSuggestionIndex];
          _onSelectSuggestion(suggestion);
        }

        break;
      }

      case "Enter": {
        e.preventDefault();
        _selectSuggestionOnEnter();
        break;
      }
      case "Esc": // IE/Edge specific value
      case "Escape": {
        e.stopPropagation();
        _onHiddenSuggestions();
        setFocusedSuggestionIndex(-1);
        inputRef.current?.blur();
        break;
      }
      default:
        break;
    }
  };

  const showEmptyStateSuggestions =
    !!emptyStateSuggestions && showSuggestions && !_value;

  const actuallyShowSuggestions =
    showSuggestions &&
    (_value || showEmptyStateSuggestions) &&
    suggestions.length > 0;

  // If the user is focusing a suggestion, preview that suggestion
  // in the text input. Otherwise, show what the user was last typing.
  let focusedSuggestionOrValue = _value;
  if (focusedSuggestionIndex !== -1) {
    focusedSuggestionOrValue = suggestions[focusedSuggestionIndex]
      ? getLabel(suggestions[focusedSuggestionIndex])
      : "";
  }

  return (
    <div
      className={classNames("InputAutocomplete", className)}
      ref={containerRef}
      onKeyDown={_onKeyDown}
    >
      <InputText
        // Disable browser autocomplete because it would cover up
        // our own suggestionsBox
        autoComplete="none"
        value={focusedSuggestionOrValue}
        onChange={_onChange}
        onFocus={_onFocus}
        onBlur={_onBlur}
        error={fetchSuggestionsError || error}
        aria-describedby={getId("initInstr")}
        aria-owns={getId("results")}
        aria-expanded={actuallyShowSuggestions}
        aria-autocomplete="both"
        aria-activedescendant={
          focusedSuggestionIndex !== -1
            ? getId(`option_${focusedSuggestionIndex}`)
            : ""
        }
        ref={inputRef}
        {...props}
      >
        {icon && (
          <span className="InputAutocomplete-InputText-Icon">{icon}</span>
        )}
      </InputText>
      {(actuallyShowSuggestions || showEmptyStateSuggestions) && (
        <div
          className={classNames(
            "InputAutocomplete-suggestionsBox",
            suggestionBoxClassName,
          )}
        >
          <ul
            className="InputAutocomplete-suggestionsList"
            data-testid="InputAutocomplete-suggestionsList"
            id={getId("results")}
            ref={suggestionsContainerRef}
            role="listbox"
            tabIndex="-1"
          >
            {suggestions.map((suggestion, suggestionIndex) => {
              const matchedSubstrings = getMatchedSubstrings(
                suggestion,
                _value,
              );
              const suggestionIsFocused =
                suggestionIndex === focusedSuggestionIndex;

              return (
                <li
                  key={suggestion.id || getLabel(suggestion)}
                  id={getId(`option_${suggestionIndex}`)}
                  ref={(ref) => {
                    suggestionsRefsArray.current[suggestionIndex] = ref;
                  }}
                  role="option"
                  aria-selected={suggestionIsFocused}
                  tabIndex="-1"
                  className={classNames("InputAutocomplete-suggestionItem", {
                    "InputAutocomplete-suggestionItem--focused":
                      suggestionIsFocused,
                  })}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    e.preventDefault();
                    _onSelectSuggestion(suggestion);
                  }}
                >
                  {matchedSubstrings && matchedSubstrings.length > 0 ? (
                    <TextWithMatchedSubstrings
                      text={getLabel(suggestion)}
                      matchedSubstrings={matchedSubstrings}
                    />
                  ) : (
                    getSuggestionLabel(suggestion)
                  )}
                </li>
              );
            })}
          </ul>
          {renderSuggestionsFooter?.()}
        </div>
      )}
      {(loading || (showSuggestionsLoading && showSuggestions)) && (
        <span className="InputAutocomplete-loadingSpinnerContainer" aria-hidden>
          <SvgSpinner color={isFocused ? "#a03fc5" : "#b5bec5"} />
        </span>
      )}
      {/* SR: These are instructions on how to use the autocomplete */}
      <span id={getId("initInstr")} style={{ display: "none" }}>
        {t("autocomplete.a11yinstructions")}
      </span>
      {/* SR: This is a hint when there are no search results */}
      {!!_value && suggestions.length === 0 && numInFlightSuggestions === 0 && (
        <span aria-live="assertive" className="sr-only">
          {t("autocomplete.noSearchResults")}
        </span>
      )}
    </div>
  );
}

InputAutocomplete.propTypes = propTypes;

export default InputAutocomplete;
