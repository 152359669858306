import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ArrowRight from "../ArrowRight/ArrowRight";
import CoverImage from "../CoverImage/CoverImage";

import "./CardImageHeader.scss";

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string.isRequired,
    sizes: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  cta: PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  onCardClickEvent: PropTypes.func,
};

const CardLink = ({ href, label, onCardClickEvent }) => {
  if (!href.startsWith("/")) {
    return (
      <a
        href={href}
        className="CardImageHeader-link"
        aria-label={label}
        title={label}
        onClick={() => {
          if (onCardClickEvent) onCardClickEvent();
        }}
      />
    );
  }

  return (
    <Link
      className="CardImageHeader-link"
      to={href}
      aria-label={label}
      title={label}
      onClick={() => {
        if (onCardClickEvent) onCardClickEvent();
      }}
    />
  );
};

CardLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onCardClickEvent: PropTypes.func,
};

const CardImageHeader = (props) => {
  const { className, title, description, img, cta, onCardClickEvent } = props;
  return (
    <article className={classNames("CardImageHeader", className)}>
      <CardLink
        href={cta.link}
        label={`${title} - ${cta.text}`}
        onCardClickEvent={onCardClickEvent}
      />
      <CoverImage
        className="CardImageHeader-imageContainer"
        src={img.src}
        srcSet={img.srcSet}
        sizes={img.sizes}
        alt={img.alt}
      />
      <div className="CardImageHeader-content">
        <h3 className="CardImageHeader-content-title">{title}</h3>
        <p className="CardImageHeader-content-description">{description}</p>
        <span className="CardImageHeader-cta">
          {cta.text}
          <span className="CardImageHeader-cta-arrow-container">
            <span className="CardImageHeader-cta-arrow-container-inner">
              <ArrowRight
                className="CardImageHeader-cta-arrow"
                pathClassName="CardImageHeader-cta-arrow-fillColor"
                width={15}
                height={12}
              />
              <ArrowRight
                className="CardImageHeader-cta-arrow"
                pathClassName="CardImageHeader-cta-arrow-fillColor"
                width={15}
                height={12}
              />
            </span>
          </span>
        </span>
      </div>
    </article>
  );
};

CardImageHeader.propTypes = propTypes;
export default CardImageHeader;
