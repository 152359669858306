import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useI18n } from "../../spages/spa/context/I18nContext";
import SearchIcon from "../Icons/SearchIcon";
import { searchIconColors } from "./GeocodingInputSearch";

import "./SearchDrawerTrigger.scss";

const propTypes = {
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  isTransparent: PropTypes.bool,
  text: PropTypes.string,
};

function SearchDrawerTrigger({
  className,
  isTransparent = false,
  dataTestId,
  onClick,
  text,
}) {
  const { t } = useI18n();
  return (
    <button
      className={classNames("SearchDrawerTrigger", className, {
        "SearchDrawerTrigger--isTransparent": isTransparent,
      })}
      data-testid={`SearchDrawerTrigger-${dataTestId}`}
      onClick={onClick}
      type="button"
    >
      {text ? (
        <span className="SearchDrawerTrigger-Text">{text}</span>
      ) : (
        <span className="SearchDrawerTrigger-Text SearchDrawerTrigger-Text--placeholder">
          {t("spages.homepage.searchBar.region.placeholder")}
        </span>
      )}
      <span className="SearchDrawerTrigger-SearchIcon">
        <SearchIcon
          fillPathColor={
            isTransparent
              ? searchIconColors.transparent
              : searchIconColors.default
          }
        />
      </span>
    </button>
  );
}

SearchDrawerTrigger.propTypes = propTypes;

export default SearchDrawerTrigger;
