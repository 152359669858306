import dataLayer from "../../../../utils/data-layer";

const getDataLayerVariables = ({
  user,
  query = {},
  regionSlug = "",
  listings = [],
  numberSearchResults,
} = {}) => {
  const baseDataLayer = dataLayer.initialDataLayer("Search Results", {
    user,
  });

  return Object.assign(baseDataLayer[0], {
    event: "search-result-loaded",
    city: regionSlug,
    region: regionSlug,
    preferred_price_range: [
      query.minPrice || "0.00",
      query.maxPrice || "19500.99",
    ],
    content_ids: listings,
    numberSearchResults,
    ...query,
  });
};

export default {
  getDataLayerVariables,
};
