import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import dayjs from "dayjs";
import intervalPoint from "../../../../../../components/DateMonthView/interval-point";
import DateWidget from "../../../../../../components/DateWidget/DateWidget";
import Popover, {
  placements,
} from "../../../../../../components/Popover/Popover";
import {
  isFromDateAvailable,
  isToDateAvailable,
} from "../../../../../../utils/fromToDatesValidation";
import getHumanReadableDateRange from "./getHumanReadableDateRange";

const DateFilter = forwardRef(({ t, onChange, onClose, from, to }, ref) => {
  const toggleButtonRef = useRef();

  const [isVisible, setIsVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    focus: () => toggleButtonRef.current.click(),
  }));

  const handleClose = () => {
    if (onClose) onClose();
    setIsVisible(false);
  };

  const toggleDateFilter = () => {
    if (isVisible) return handleClose();

    setIsVisible(true);
  };

  const [toHovered, setToHovered] = useState(null);
  const onHoverDayTo = (day) => {
    setToHovered(day && dayjs.utc(day).format("YYYY-MM-DD"));
  };

  const getMinDateFrom = () => {
    return dayjs.utc().format("YYYY-MM-DD");
  };

  const getMinDateTo = () => {
    return dayjs
      .utc(from || getMinDateFrom())
      .add(1, "month")
      .subtract(1, "day")
      .format("YYYY-MM-DD");
  };

  const onFromChange = (date) => {
    if (dayjs(date).isBefore(getMinDateFrom())) {
      return;
    }

    onChange({ from: date, to: null });
  };

  const onToChange = (date) => {
    // "Clear date" button is triggered
    if (!date) {
      return onChange({ from: null, to: null });
    }

    onChange({ from, to: date });
    handleClose();
  };

  return (
    <div className="SearchBarForm-Input SearchBarForm-Input-DateFilter">
      <Popover
        placement={placements.bottomStart}
        show={isVisible}
        onClose={handleClose}
        usePortal={false}
        hasAnimation={false}
        around={
          <button
            className="SearchBarForm-DateFilterToggleButton"
            type="button"
            onClick={toggleDateFilter}
            ref={toggleButtonRef}
          >
            <div className="SearchBarForm-Input-content">
              <span className="SearchBarForm-Input-title">
                {t("moveIn")} / {t("moveOut")}
              </span>

              {from || to ? (
                <span className="SearchBarForm-Input-value">
                  {getHumanReadableDateRange({ from, to, t })}
                </span>
              ) : (
                <span
                  className={classNames("SearchBarForm-Input-placeholder", {
                    "SearchBarForm-Input-placeholder--active": isVisible,
                  })}
                >
                  {t("spages.homepage.searchBar.date.placeholder")}
                </span>
              )}
            </div>
          </button>
        }
      >
        <div className="HomePageDateFilter">
          <div className="HomePageDateFilter-widget HomePageDateFilter-from">
            <span className="HomePageDateFilter-widget-title">{t("from")}</span>
            <DateWidget
              t={t}
              selected={from}
              isDateAvailable={(date) => isFromDateAvailable({ date })}
              onSelect={onFromChange}
              setAttributes={intervalPoint(toHovered || to)}
              clearButtonText={t("today")}
              minDate={getMinDateFrom()}
            />
          </div>
          <div className="HomePageDateFilter-widget HomePageDateFilter-to">
            <span className="HomePageDateFilter-widget-title">{t("to")}</span>
            <DateWidget
              t={t}
              selected={to}
              isDateAvailable={(date) =>
                isToDateAvailable({
                  from,
                  date,
                })
              }
              minDate={getMinDateTo()}
              onSelect={onToChange}
              setAttributes={intervalPoint(from)}
              onHoverDay={onHoverDayTo}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
});

DateFilter.propTypes = {
  from: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  t: PropTypes.func.isRequired,
  to: PropTypes.string,
};

export default DateFilter;
