/* eslint-disable react/prop-types */
import React from "react";
import escapeHtml from "escape-html";
import { Helmet } from "react-helmet";
import jsonStringifySafe from "../../../utils/json-stringify-safe";

const getRobotsTag = ({ noIndex, index, follow, noFollow } = {}) => {
  const content = [];

  if (noIndex) {
    content.push("noindex");
  }

  if (index) {
    content.push("index");
  }

  if (follow) {
    content.push("follow");
  }

  if (noFollow) {
    content.push("nofollow");
  }

  return content.length === 0 ? null : (
    <meta name="robots" content={content.join(",")} />
  );
};

const PageHead = ({
  title = "page",
  description,
  canonicalUrl,
  prev,
  next,
  translationUrls = {},
  ogImage = "https://wunderflats.com/public/images/fb_og_preview.png",
  noIndex: noIndexFromProps,
  index,
  follow,
  structuredData,
  ogTitle,
  children,
  noFollow,
  params,
  lang,
  hasCategory,
}) => {
  // Disable indexing for Ukrainian & Russian pages
  const noIndex = ["uk", "ru"].includes(lang) || noIndexFromProps;

  /* SEO: if the page is a Category Page, then hreflang should contain canonical url and redirectHrefLang query param for all languages except the current one.
   */
  const determineHrefLang = (urlLang) => {
    if (!hasCategory) return translationUrls[urlLang];

    return params?.id || lang === urlLang
      ? canonicalUrl
      : `${canonicalUrl}?redirectHrefLang=${urlLang}`;
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        property="og:title"
        content={`${escapeHtml(ogTitle || title)} - Wunderflats`}
      />
      {description && (
        <meta name="description" content={escapeHtml(description)} />
      )}
      {description && (
        <meta property="og:description" content={escapeHtml(description)} />
      )}
      {canonicalUrl && (
        <link
          data-testid="canonicalUrl"
          rel="canonical"
          href={`${escapeHtml(canonicalUrl)}`}
        />
      )}
      {canonicalUrl && (
        <meta property="og:url" content={escapeHtml(canonicalUrl)} />
      )}
      {prev && <link rel="prev" href={escapeHtml(prev)} />}
      {next && <link rel="next" href={escapeHtml(next)} />}
      {ogImage && <meta property="og:image" content={escapeHtml(ogImage)} />}
      {Object.keys(translationUrls).map((urlLang) => (
        <link
          key={translationUrls[urlLang]}
          rel="alternate"
          hrefLang={urlLang}
          href={`${determineHrefLang(urlLang)}`}
        />
      ))}
      {getRobotsTag({ noIndex, index, follow, noFollow })}
      {structuredData && (
        <script type="application/ld+json">
          {jsonStringifySafe(structuredData)}
        </script>
      )}
      {children}
    </Helmet>
  );
};

export default PageHead;
