import React from "react";

const House2Icon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_5" data-name="Layer 5">
          <rect fill="none" width="20" height="20" />
          <path
            style={{
              isolation: "isolate",
              opacity: 0.1,
            }}
            d="M20,19.92H2.6L.78,18.72H19.23Z"
          />
          <path fill="#edf1f4" d="M18.77,18.17H12V7.69l3.37-2.1,3.37,2.1Z" />
          <path
            fill="#24272e"
            d="M16.53,8.77h0a.78.78,0,0,0-.78.78V11.1h1.56V9.55A.78.78,0,0,0,16.53,8.77Z"
          />
          <path fill="#24272e" d="M17.31,11.46H15.75v.78h1.56Z" />
          <path
            fill="#24272e"
            d="M16.53,13.08h0a.78.78,0,0,0-.78.78v1.56h1.56V13.86A.78.78,0,0,0,16.53,13.08Z"
          />
          <path fill="#24272e" d="M17.31,15.78H15.75v.78h1.56Z" />
          <path
            fill="#24272e"
            d="M14.28,8.77h0a.78.78,0,0,0-.78.78V11.1h1.56V9.55A.78.78,0,0,0,14.28,8.77Z"
          />
          <path fill="#24272e" d="M15.06,11.46H13.5v.78h1.56Z" />
          <path
            fill="#24272e"
            d="M14.28,13.08h0a.78.78,0,0,0-.78.78v1.56h1.56V13.86A.78.78,0,0,0,14.28,13.08Z"
          />
          <path fill="#24272e" d="M15.06,15.78H13.5v.78h1.56Z" />
          <path
            style={{
              isolation: "isolate",
              opacity: 0.1,
            }}
            d="M14.66,18.2H12.74V7.3l1.92-1.74Z"
          />
          <path fill="#fff" d="M12,17.94H1.53V4L6.78,1.16,12,4Z" />
          <path
            fill="#24272e"
            d="M19.27,7.41,15.4,5,12.78,6.63V3.52L6.78.31l-6,3.21V18.69H19.27Zm-8,9.78h-9V4.42L6.78,2l4.5,2.41Zm7,.49H12.78V7.81L15.4,6.18,18.27,8Z"
          />
          <path
            fill="#24272e"
            d="M5.14,6h0a1.29,1.29,0,0,0-1.36,1.2V8.53H6.49V7.21A1.29,1.29,0,0,0,5.14,6Z"
          />
          <path fill="#24272e" d="M6.49,8.89H3.78v.84H6.49Z" />
          <path
            fill="#24272e"
            d="M8.65,6h0a1.29,1.29,0,0,0-1.36,1.2V8.53H10V7.21A1.29,1.29,0,0,0,8.65,6Z"
          />
          <path fill="#24272e" d="M10,8.89H7.29v.84H10Z" />
          <path
            fill="#24272e"
            d="M5.14,11.4h0a1.29,1.29,0,0,0-1.36,1.2v1.32H6.49V12.6A1.29,1.29,0,0,0,5.14,11.4Z"
          />
          <path fill="#24272e" d="M6.49,14.34H3.78v.84H6.49Z" />
          <path
            fill="#24272e"
            d="M8.65,11.4h0a1.29,1.29,0,0,0-1.36,1.2v1.32H10V12.6A1.29,1.29,0,0,0,8.65,11.4Z"
          />
          <path fill="#24272e" d="M10,14.34H7.29v.84H10Z" />
        </g>
      </g>
    </svg>
  );
};

export default House2Icon;
