function intervalPoint(intervalPoint) {
  if (!intervalPoint) return (date, attrs) => attrs;

  return function setAttributes(date, attrs, selected, hovered) {
    const compareWith = selected || hovered || null;

    if (!compareWith) {
      return attrs;
    }

    const inInterval =
      compareWith &&
      intervalPoint &&
      (compareWith.isBefore(intervalPoint)
        ? date.isBetween(compareWith, intervalPoint)
        : date.isBetween(intervalPoint, compareWith));

    return Object.assign(attrs, {
      intervalPoint: date.isSame(intervalPoint, "day"),
      inInterval,
    });
  };
}

export default intervalPoint;
