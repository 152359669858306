import React from "react";
import PropTypes from "prop-types";

const InfoSection = ({ t }) => {
  return (
    <article className="InfoSection">
      <h2 className="InfoSection-headline">
        <span className="InfoSection-headline-highlight">
          {t("spages.homepage.infoSection.textHighlight")}
        </span>
        <span className="InfoSection-headline-text">
          {t("spages.homepage.infoSection.text")}
        </span>
      </h2>
      <article className="InfoSection-container">
        <div className="InfoSection-container-item">
          <img
            loading="lazy"
            className="InfoSection-container-item-icon DocumentsIcon"
            src="/public/images/home-page/infoSection/DocumentsIcon.svg"
            alt="Documents icon"
          />
          <div className="InfoSection-container-item-text">
            <p className="InfoSection-container-item-text-title">
              {t("spages.homepage.infoSection.smartContracts.title")}
            </p>
            <p className="InfoSection-container-item-text-description">
              {t("spages.homepage.infoSection.smartContracts.description")}
            </p>
          </div>
        </div>

        <div className="InfoSection-container-item">
          <img
            loading="lazy"
            className="InfoSection-container-item-icon VerifiedListingIcon"
            src="/public/images/home-page/infoSection/VerifiedListingIcon.svg"
            alt="Verified Listing Icon"
          />
          <div className="InfoSection-container-item-text">
            <p className="InfoSection-container-item-text-title">
              {t("spages.homepage.infoSection.verifiedApartments.title")}
            </p>
            <p className="InfoSection-container-item-text-description">
              {t("spages.homepage.infoSection.verifiedApartments.description")}
            </p>
          </div>
        </div>

        <div className="InfoSection-container-item">
          <img
            loading="lazy"
            className="InfoSection-container-item-icon CustomerSupportIcon"
            src="/public/images/home-page/infoSection/CustomerSupportIcon.svg"
            alt="Customer Support Icon"
          />
          <div className="InfoSection-container-item-text">
            <p className="InfoSection-container-item-text-title">
              {t("spages.homepage.infoSection.personalSupport.title")}
            </p>
            <p className="InfoSection-container-item-text-description">
              {t("spages.homepage.infoSection.personalSupport.description")}
            </p>
          </div>
        </div>
      </article>
    </article>
  );
};

InfoSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default InfoSection;
