import React from "react";
import PropTypes from "prop-types";
import * as amplitude from "@amplitude/analytics-browser";
import { misc } from "@wunderflats/constants";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useFeatureFlags } from "../../../../../contexts/FeatureFlagContext";
import { useTracker } from "../../../context/TrackerContext";
import { categoryDataToSliderProps } from "../../ListingsPage/ListingsPage/utils";
import HomePageSlider from "../HomePageSlider/HomePageSlider";
import Slide from "../HomePageSlider/Slide";
import Caption from "./Caption";

const {
  FEATURE_FLAGS: { ENVIRONMENT_REFUGEE_DISTRIBUTION },
  labels: { CATEGORY_HOMES_FOR_UKRAINIANS },
} = misc;

const HELFENDE_WAENDE_IMAGE = {
  en: "https://images.prismic.io/wunderflatscontent/3c36633a-77fb-4074-adf4-57715760024a_HW-Collection-EN.png?auto=compress,format",
  de: "https://images.prismic.io/wunderflatscontent/2ebfa11f-61d5-40e7-b5be-54c661d1ec1b_HW-Collection-DE.png?auto=compress,format",
  uk: "https://images.prismic.io/wunderflatscontent/8081f9cf-1b02-4d7e-bf26-7d6738b1dd91_HW-Collection-UKR.png?auto=compress,format",
  ru: "https://images.prismic.io/wunderflatscontent/bd060c44-7d62-4dc1-8251-71bf7e1907c6_HW-Collection-RU.png?auto=compress,format",
};

const propTypes = {
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  regionSlug: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  query: PropTypes.object,
  analytics: PropTypes.object,
  defaultFilters: PropTypes.object,
};

const Collections = ({
  t,
  url,
  categories,
  regionSlug,
  lang,
  query,
  analytics,
}) => {
  const { user } = useAuth();
  const { tracker } = useTracker();

  const { isFeatureFlagEnabled } = useFeatureFlags();
  // TODO dev-10309 remove feature flag
  const showRefugeeDistributionFeatures = isFeatureFlagEnabled(
    ENVIRONMENT_REFUGEE_DISTRIBUTION,
  );

  const categoriesArray = categoryDataToSliderProps({
    categoryData: categories,
    region: regionSlug,
    lang,
    url,
    query,
    showRefugeeDistributionFeatures,
  });

  const trackSlideClick = ({ categoryLabel, position, url, categoryTitle }) => {
    amplitude.track("Homepage Slider Clicked", {
      destination_path: url,
      item_id: categoryLabel,
      item_name: categoryTitle,
    });

    analytics.event(
      "HomePage",
      "home_click_carousel_collections",
      `${categoryLabel}_${position}`,
    );

    tracker.events.trackCollectionClickedOnHomepage({
      userId: user?._id || null,
      location: regionSlug,
      collectionId: categoryLabel,
    });
  };

  return (
    <HomePageSlider
      className="Collections"
      title={t("pages.CategoriesPage.slider.kicker")}
      subtitle={t("pages.CategoriesPage.slider.title")}
      hasHover
      slides={categoriesArray.map((category, i) => {
        const loading = i <= 3 ? "eager" : "lazy";
        const shouldShowHelfendeWaendeLogo =
          showRefugeeDistributionFeatures &&
          category.label === CATEGORY_HOMES_FOR_UKRAINIANS;

        return (
          <Slide
            className={category.label}
            url={category.url}
            alt={category.title}
            title={category.title}
            src={category.src}
            srcSet={
              shouldShowHelfendeWaendeLogo
                ? HELFENDE_WAENDE_IMAGE[lang]
                : category.srcSet
            }
            loading={loading}
            caption={
              <Caption
                svgTitle={category.svgTitle}
                title={category.title}
                subtitle={category.tagline}
                loading={loading}
              />
            }
            onClickSlide={() =>
              trackSlideClick({
                categoryLabel: category.label,
                position: i + 1,
                url,
                categoryTitle: category.title,
              })
            }
          />
        );
      })}
    />
  );
};

Collections.propTypes = propTypes;

export default Collections;
