import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useI18n } from "../../spages/spa/context/I18nContext";
import ArrowRight from "../ArrowRight/ArrowRight";
import House1Icon from "../Icons/SearchInput/House1Icon";
import House2Icon from "../Icons/SearchInput/House2Icon";
import House3Icon from "../Icons/SearchInput/House3Icon";
import House4Icon from "../Icons/SearchInput/House4Icon";
import NeighborhoodIcon from "../Icons/SearchInput/NeighborhoodIcon";
import "./GeocodingSuggestion.scss";

GeocodingSuggestion.propTypes = {
  place: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    context: PropTypes.string,
    placeType: PropTypes.arrayOf(
      PropTypes.oneOf([
        "postcode",
        "place",
        "locality",
        "neighborhood",
        "region",
      ]),
    ),
    region: PropTypes.string,
  }).isRequired,
  hideSuggestionArrow: PropTypes.bool,
};

const cityIcons = [House1Icon, House2Icon, House3Icon, House4Icon];
function GeocodingSuggestion({ place, hideSuggestionArrow }) {
  const CityIcon = useMemo(() => {
    const chosen = parseInt(Math.random() * cityIcons.length, 10);
    return cityIcons[chosen];
  }, []);

  const isCity = place.placeType.some((p) => p === "place");

  const Icon = isCity ? CityIcon : NeighborhoodIcon;

  const { t } = useI18n();

  const title = isCity ? t(`city`) : t(`neighbourhood`);

  return (
    <div className="GeocodingSuggestion-container" data-region={place.region}>
      <div className="GeocodingSuggestion-textAndIconContainer">
        <div title={title} className="GeocodingSuggestion-iconContainer">
          <Icon className="GeocodingSuggestion-icon" />
        </div>
        <div className="GeocodingSuggestion-textContainer">
          <strong>{place.text}</strong>
          <p className="GeocodingSuggestion-textContext">{place.context}</p>
        </div>
      </div>
      {!hideSuggestionArrow && (
        <ArrowRight
          className="GeocodingSuggestion-arrow"
          height={15}
          width={17}
          fillColor="#575D64"
        />
      )}
    </div>
  );
}

export default GeocodingSuggestion;
