import { misc } from "@wunderflats/constants";
import qs from "qs";
import dataLayerUtils from "../../../../../utils/data-layer";
import { isDefaultFilter } from "../../../../../utils/filterUtils";
import srpSeoUrls from "../../../../../utils/srp-seo-urls";
import dataLayer from "../data-layer";
import getListingStructuredData from "../structured-data";

// Same value as $listingspage-tablet-breakpoint from src/styles/variables/dimensions.scss
export const LISTINGS_PAGE_TABLET_BREAKPOINT = 1145;

export const getRefugeeSearchLocationText = ({
  t,
  municipality,
  federalState,
}) => {
  if (municipality)
    return t(
      "pages.ListingsPage.header.refugeeSearchArea.municipality.location",
      {
        federalState,
        municipality,
      },
    );
  if (federalState)
    return t(
      "pages.ListingsPage.header.refugeeSearchArea.federalState.location",
      {
        federalState,
      },
    );
  /** We currently only support Germany as a country for refugees. If this ever changes,
   * we need to change this code.
   */
  return t("pages.ListingsPage.header.refugeeSearchArea.germany");
};

export const generateSrcSet = ({ images }) => {
  return images
    .map((image) => {
      if (!image || !image.url || !image.width) {
        return null;
      }
      return `${image.url} ${image.width}w`;
    })
    .filter((imageSrc) => !!imageSrc)
    .join(", ");
};

export const categoryDataToSliderProps = ({
  categoryData,
  region,
  lang,
  url,
  query,
  showRefugeeDistributionFeatures, // TODO dev-10309 remove feature flag
}) => {
  const getCategoryUrl = (category) =>
    category.label === misc.labels.CATEGORY_HOMES_FOR_UKRAINIANS &&
    showRefugeeDistributionFeatures
      ? url(
          "categories",
          {
            region: "germany", // Hardcoded for refugee collection since we only support Germany as a country for now
            category: category.slug,
            lang,
          },
          { query: { ...query, showPlaceOfResidenceModal: true } },
        )
      : url(
          "categories",
          {
            region,
            category: category.slug,
            lang,
          },
          { query },
        );
  return categoryData.map((category) => {
    return {
      src: category?.heroImagePortrait?.[0]?.url,
      alt: category?.heroImagePortrait?.[0]?.alt,
      title: category?.title,
      fontName: category?.titleFontFamily,
      url: getCategoryUrl(category),
      srcSet: generateSrcSet({ images: category.heroImagePortrait }),
      label: category?.label,
      lang: category?.lang,
      tagline: category?.tagline,
      svgTitle: category?.svgTitle,
      slug: category?.slug,
    };
  });
};

export const DEFAULT_CITY = "berlin";

export const buildStructuredData = ({
  query,
  capitalizedRegionSlug,
  total,
  listings = [],
  lowPrice,
  highPrice,
  lang,
  t,
}) => {
  let structuredData = [];
  // Structured data with lowPrice and highPrice
  if (isDefaultFilter(query)) {
    structuredData.push({
      "@context": "https://schema.org",
      "@type": ["Apartment", "Product"],
      name: t("pages.ListingsPage.documentTitle", {
        region: capitalizedRegionSlug,
      }),
      description: t("pages.ListingsPage.meta.description", {
        region: capitalizedRegionSlug,
      }),
      offers: {
        "@type": "AggregateOffer",
        lowPrice,
        highPrice,
        offerCount: total,
        priceCurrency: "EUR",
      },
    });
  }

  // Structured data with listings on the page.
  const structuredDataWithListings = isDefaultFilter(query)
    ? listings.map((listing) =>
        getListingStructuredData({
          listing,
          language: lang,
        }),
      )
    : [];

  structuredData = structuredData.concat(structuredDataWithListings);

  return structuredData;
};

export const generateHeadContent = ({
  t,
  capitalizedRegionSlug,
  pageDataContent,
  metaTitle,
}) => {
  const content = {
    ...{
      title: t("pages.ListingsPage.title", {
        region: capitalizedRegionSlug,
      }),
      metaTitle,
      metaDescription: t("pages.ListingsPage.meta.description", {
        region: capitalizedRegionSlug,
      }),
    },
    ...pageDataContent,
  };

  return content;
};

export const getHeadProps = ({
  listings = [],
  total,
  itemsPerPage,
  query,
  capitalizedRegionSlug,
  lowPrice,
  highPrice,
  lang,
  t,
  content,
  category,
  paramsPage,
  region,
  translationUrls,
  hasCategory,
}) => {
  const page = paramsPage || "1";
  const totalPages = Math.ceil(total / itemsPerPage);

  const relatedUrls = srpSeoUrls.generateUrls({
    language: lang,
    regionSlug: region,
    categorySlug: category?.slug,
    query: qs.stringify(query, { addQueryPrefix: true }),
    page: parseInt(page, 10),
    totalPages,
    routeName: category?.slug ? "categories" : "listinglist",
  });

  // If the page is the result of a search, of a movement of the map,
  // or the page has less than 8 listing, then do not index the page.
  const index =
    !query.search &&
    !query.bbox &&
    !["uk", "ru"].includes(lang) && // Do not index for Russian & Ukrainian pages
    total >= 8 &&
    (category?.label === misc.labels.CATEGORY_HOMES_FOR_UKRAINIANS) === false;

  const noIndex = !!query.search || !!query.bbox;

  const structuredData = buildStructuredData({
    query,
    capitalizedRegionSlug,
    total,
    listings,
    lowPrice,
    highPrice,
    lang,
    t,
  });

  return {
    title: category
      ? `${category.title} ${capitalizedRegionSlug}`
      : content.metaTitle,
    description: category ? category.description : content.metaDescription,
    prev: relatedUrls.prev,
    next: relatedUrls.next,
    canonicalUrl: relatedUrls.canonical,
    translationUrls,
    noIndex,
    index,
    follow: true,
    structuredData,
    lang,
    hasCategory,
  };
};

export const sanitizeRegions = ({ regions, t }) => {
  return regions
    .filter(({ disabled }) => {
      return !disabled;
    })
    .map((region) => ({
      slug: region.slug,
      label: t(`regions.${region.slug}`),
      name: t(`regions.${region.slug}`),
      center: region.center,
    }))
    .sort((regionA, regionB) => {
      if (regionA.label > regionB.label) return 1;
      if (regionA.label < regionB.label) return -1;
      return 0;
    });
};

export const shouldShowCityDescription = ({ content, page }) => {
  return (
    content &&
    content.h2s &&
    content.h2s.length > 0 &&
    (!page || Number(page) === 1)
  );
};

export const getRandomKey = () => String(Math.round(Math.random() * 10000));

// Analytics

export const trackListingClickEvent = ({
  listingResultsTotal,
  listingResultsItemsPerPage,
  paramPage = "1",
  tracker,
  listing,
  itemIndexOnPage,
  clickPlacement,
}) => {
  tracker.events.trackListingsSearchResultClick({
    pageNumber: Number(paramPage),
    clickPlacement,
    pageSize: listingResultsItemsPerPage,
    itemIndexOnPage,
    listingResultsTotal,
    listing,
  });
};

export const persistDataLayerVars = ({
  listingResults,
  dataLayerKey,
  user,
  regionSlug,
  query,
}) => {
  dataLayerUtils.pushToDataLayer(
    dataLayer.getDataLayerVariables({
      user,
      regionSlug,
      query,
      listings: listingResults.items?.map((listing) => listing.groupId),
      numberSearchResults: listingResults.total,
    }),
  );

  dataLayerUtils.persistDataLayerVariables(dataLayerKey, [
    "city",
    "region",
    "preferred_price_range",
    "content_ids",
    "from",
    "to",
    "minAccommodates",
    "minPrice",
    "maxPrice",
    "minRooms",
    "minSize",
    "amenities",
  ]);
};
