import React from "react";

const House1Icon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_5" data-name="Layer 5">
          <rect fill="none" width="20" height="20" />
          <path fill="#edf1f4" d="M5.65,18.18H.85V7.7l2.4-2.1,2.4,2.1Z" />
          <path fill="#24272e" d="M3.91,8.78H2.35v2.33H3.91Z" />
          <path fill="#24272e" d="M3.91,11.47H2.35v.78H3.91Z" />
          <path fill="#24272e" d="M3.91,13.09H2.35v2.34H3.91Z" />
          <path fill="#24272e" d="M3.91,15.79H2.35v.78H3.91Z" />
          <path
            style={{
              isolation: "isolate",
              opacity: 0.1,
            }}
            d="M20,19.92H2.13L.41,18.72l18.86,0Z"
          />
          <path fill="#edf1f4" d="M18.77,18.18H14V7.7l2.39-2.1,2.4,2.1Z" />
          <rect fill="#24272e" x="15.66" y="8.78" width="1.56" height="2.34" />
          <rect fill="#24272e" x="15.66" y="11.47" width="1.56" height="0.78" />
          <rect fill="#24272e" x="15.66" y="13.09" width="1.56" height="2.34" />
          <rect fill="#24272e" x="15.66" y="15.79" width="1.56" height="0.78" />
          <path
            style={{
              isolation: "isolate",
              opacity: 0.1,
            }}
            d="M16.25,18.3H14.34V7.4l1.91-1.74Z"
          />
          <path fill="#fff" d="M14,17.94H5.59V4L9.78,1.16,14,4Z" />
          <path
            fill="#24272e"
            d="M16.37,4.94,14.73,6.38V3.57L9.78.25,4.84,3.57V6.33L3.25,4.94.36,7.47V18.68H19.27V7.47Zm-15,12.74V7.93L3.25,6.27,4.84,7.65v10Zm11.87-.5H6.34V4.37L9.78,2.06l3.45,2.31Zm5,.5H14.73v-10l1.64-1.44,1.9,1.66Z"
          />
          <path fill="#24272e" d="M9.36,6H7.69V8.53H9.36Z" />
          <path fill="#24272e" d="M9.36,8.89H7.69v.84H9.36Z" />
          <path fill="#24272e" d="M11.88,6H10.2V8.53h1.68Z" />
          <path fill="#24272e" d="M11.88,8.89H10.2v.84h1.68Z" />
          <path fill="#24272e" d="M9.36,11.4H7.69v2.52H9.36Z" />
          <path fill="#24272e" d="M9.36,14.34H7.69v.84H9.36Z" />
          <path fill="#24272e" d="M11.88,11.4H10.2v2.52h1.68Z" />
          <path fill="#24272e" d="M11.88,14.34H10.2v.84h1.68Z" />
        </g>
      </g>
    </svg>
  );
};

export default House1Icon;
