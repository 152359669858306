import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { IconButton } from "../../../../../components/DesignSystem/IconButton/IconButton";
import SliderArrow from "../../../../../components/SliderArrow/SliderArrow";
import { useI18n } from "../../../context/I18nContext";

const propTypes = {
  header: PropTypes.node,
  slides: PropTypes.arrayOf(PropTypes.node),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  hasHover: PropTypes.bool,
};

const HomePageSlider = ({
  header,
  slides,
  title,
  subtitle,
  className,
  hasHover,
}) => {
  const slideContainerRef = useRef();
  const leftArrow = useRef();
  const rightArrow = useRef();
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [hasOverflow, setHasOverflow] = useState();
  const [slideWidth, setSlideWidth] = useState();
  const { t } = useI18n();

  const checkForScrollPosition = () => {
    const { scrollLeft, scrollWidth, clientWidth } = slideContainerRef.current;
    setHasOverflow(scrollWidth > clientWidth);
    // when the homepage slider has hover effect we need 7px of clearance for it
    setCanScrollLeft(scrollLeft > 7);
    setCanScrollRight(
      Math.trunc(scrollLeft) < Math.trunc(scrollWidth - clientWidth),
    );
  };

  const scrollImgContainerBy = (distance) => {
    slideContainerRef.current.scrollBy({
      left: distance,
      behavior: "smooth",
    });
  };

  const handleArrowClick = (scrollDistance = 0) => {
    scrollImgContainerBy(scrollDistance);
    checkForScrollPosition();
  };

  useEffect(() => {
    let slideContainerRefValue = null;

    const slideElt =
      slideContainerRef.current?.querySelector("[data-slide-index]");

    if (!slideElt) {
      return;
    }

    setSlideWidth(slideElt.getBoundingClientRect().width);
    checkForScrollPosition();
    slideContainerRef.current.addEventListener(
      "scroll",
      checkForScrollPosition,
    );

    const onResize = debounce(() => {
      if (!slideContainerRef.current) {
        return;
      }

      const { scrollLeft, scrollWidth, clientWidth } =
        slideContainerRef.current;

      setHasOverflow(scrollWidth > clientWidth);
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(
        Math.trunc(scrollLeft) !== Math.trunc(scrollWidth - clientWidth),
      );
    }, 200);

    window.addEventListener("resize", onResize);

    if (slideContainerRef.current) {
      slideContainerRefValue = slideContainerRef.current;
    }

    return () => {
      slideContainerRefValue?.removeEventListener(
        "scroll",
        checkForScrollPosition,
      );
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <section
      className={classNames(
        "HomePageSlider",
        className,
        hasHover && "HomePageSlider--hasHover",
      )}
    >
      <header className="HomePageSlider-header ContentContainer">
        {header}
        {(title || subtitle) && (
          <div className="HomePageSlider-headerTitle">
            {title && <h3 className="HomePageSlider-title">{title}</h3>}
            {subtitle && (
              <h2 className="HomePageSlider-subtitle">{subtitle}</h2>
            )}
          </div>
        )}
        {hasOverflow ? (
          <nav className="HomePageSlider-header-arrows">
            <IconButton
              data-testid="leftArrow"
              type="button"
              ref={leftArrow}
              className="HomePageSlider-header-arrows-arrow"
              name={t("spages.homepage.homepageSlider.button.previous")}
              disabled={!canScrollLeft}
              onClick={() => handleArrowClick(-slideWidth)}
              size="large"
              icon={
                <SliderArrow
                  fillColor={canScrollLeft ? "#24272E" : "#B5BEC5"}
                  className="left"
                />
              }
            />
            <IconButton
              data-testid="rightArrow"
              ref={rightArrow}
              type="button"
              className="HomePageSlider-header-arrows-arrow"
              name={t("spages.homepage.homepageSlider.button.next")}
              disabled={!canScrollRight}
              onClick={() => handleArrowClick(slideWidth)}
              size="large"
              icon={
                <SliderArrow
                  fillColor={canScrollRight ? "#24272E" : "#B5BEC5"}
                  className="right"
                />
              }
            />
          </nav>
        ) : null}
      </header>
      <div className="HomePageSlider-scrollContainer" ref={slideContainerRef}>
        {slides.map((slide, i) => (
          <article
            className="HomePageSlider-slide"
            key={i}
            data-slide-index={i}
          >
            {slide}
          </article>
        ))}
      </div>
    </section>
  );
};

HomePageSlider.propTypes = propTypes;

export default HomePageSlider;
