import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import CoverImage from "../../../../../components/CoverImage/CoverImage";

const propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  caption: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.oneOf(["eager", "lazy"]),
  onClickSlide: PropTypes.func,
};

const Slide = ({
  url,
  alt,
  src,
  srcSet,
  caption,
  className,
  title,
  loading = "lazy",
  sizes = "300px",
  onClickSlide = () => {},
}) => {
  return (
    <div className={classNames("Slide", className)}>
      {url && (
        <Link
          data-testid="Slide-link"
          className="Slide-link"
          title={title}
          to={url}
          onClick={onClickSlide}
        />
      )}
      <CoverImage
        src={src}
        srcSet={srcSet}
        sizes={sizes}
        alt={alt}
        loading={loading}
        className="Slide-figure"
        imageClassName="Slide-coverImage"
      />
      {caption}
    </div>
  );
};

Slide.propTypes = propTypes;

export default Slide;
