/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { getGeocodingItemLabel } from "../../utils/geocodingHelpers";
import Drawer from "../Drawer/Drawer";
import GeocodingInputSearch from "./GeocodingInputSearch";
import SearchDrawerTrigger from "./SearchDrawerTrigger";

import "./GeocodingInputSearchMobile.scss";

const propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  isTransparent: PropTypes.bool,
  bias: PropTypes.string,
  onSelectSuggestion: PropTypes.func,
};

function GeocodingInputSearchMobile({
  className,
  isTransparent = false,
  dataTestId,
  bias,
  onSelectSuggestion,
  value,
}) {
  const { t } = useI18n();
  const [isVisible, setIsVisible] = useState(false);
  const [caretVisible, setCaretVisible] = useState(false);
  const [label, setLabel] = useState(value);

  const inputRef = useRef();
  const hiddenInputRef = useRef();

  const onCloseDrawer = () => {
    setIsVisible(false);
    // We hide the caret and show it only when the drawer is fully open
    // in order to avoid seeing the caret following the drawer while opening
    setCaretVisible(false);
  };

  const handleOnSelectSuggestion = (selected) => {
    setLabel(getGeocodingItemLabel(selected));
    onCloseDrawer();

    if (onSelectSuggestion) onSelectSuggestion(selected);
  };

  const openKeyboardIOSMobile = () => {
    hiddenInputRef.current?.focus();
  };

  const onOpenedDrawer = () => {
    if (isVisible) {
      inputRef.current?.focus();
      setCaretVisible(true);
    }
  };

  const preventBounceIOSMobile = () => {
    // Scroll to the top first to prevent bouncy effect on Drawer open
    window.scroll(0, 0);
  };

  return (
    <React.Fragment>
      {/**
       *  The 'hidden' input is important because we need to immediately focus an input
       *  at the onClick of the drawerTrigger button, if we want iOS on mobile to
       *  open the keyboard
       */}
      <input
        ref={hiddenInputRef}
        style={{ position: "absolute", left: "-999999px", height: 0, width: 0 }}
      />
      <div
        data-testid={dataTestId}
        className={classNames("GeocodingInputSearchMobile", className)}
      >
        <SearchDrawerTrigger
          dataTestId={dataTestId}
          text={label}
          isTransparent={isTransparent}
          onClick={(e) => {
            e.preventDefault();
            preventBounceIOSMobile();
            setIsVisible(true);
            openKeyboardIOSMobile();
          }}
        />
        <Drawer
          dataTestId="GeocodingInputSearchMobile-DrawerId"
          heightPercent={95}
          isVisible={isVisible}
          className="GeocodingInputSearchMobile-Drawer"
          onCloseDrawer={onCloseDrawer}
          onOpenedDrawer={onOpenedDrawer}
        >
          <GeocodingInputSearch
            inputRef={inputRef}
            value={label}
            className={`GeocodingInputSearchMobile-Drawer-GeocodingInputSearch ${
              !caretVisible ? "hide-caret" : ""
            }`}
            placeholder={t("spages.homepage.searchBar.region.placeholder")}
            onSelectSuggestion={(selected) =>
              handleOnSelectSuggestion(selected)
            }
            bias={bias}
            keepSuggestions
          />
        </Drawer>
      </div>
    </React.Fragment>
  );
}

GeocodingInputSearchMobile.propTypes = propTypes;

export default GeocodingInputSearchMobile;
