import PropTypes from "prop-types";

export default {
  analytics: PropTypes.shape({
    category: PropTypes.func.isRequired,
    event: PropTypes.func.isRequired,
  }),
  api: PropTypes.object.isRequired,
  currentUrl: PropTypes.string.isRequired,
  getData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isAPhone: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  translationUrls: PropTypes.object.isRequired,
  url: PropTypes.func.isRequired,
  user: PropTypes.object,
  // they depend on the component
  pageData: PropTypes.any,
  params: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.object),
};
