import dayjs from "dayjs";

const getHumanReadableDateRange = ({ t, from, to }) => {
  const dayjsTo = to && dayjs.utc(to);
  const dayjsFrom = from && dayjs.utc(from);

  if (dayjsFrom && dayjsTo) {
    const isSameYear = dayjsFrom.year() === dayjsTo.year();

    return `${
      isSameYear ? dayjsFrom.format("MMM DD") : dayjsFrom.format("MMM DD, YYYY")
    } - ${dayjsTo.format("MMM DD, YYYY")}`;
  }

  if (dayjsFrom) {
    return `${t("from")} ${dayjsFrom.format("MMM DD, YYYY")}`;
  }

  return t("spages.homepage.searchBar.date.placeholder");
};

export default getHumanReadableDateRange;
