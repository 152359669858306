/**
 * src/components/FormElements/InputAutocomplete/TextWithMatchedSubstrings.js
 *
 * Usage:
 *
 *    const text = "Alexanderstraße 5, Berlin, Germany";
 *    const matchedSubstrings = [
 *      { offset: 0, length: 5 },
 *      { offset: 16, length: 1 },
 *    ];
 *
 *    return (
 *      <TextWithMatchedSubstrings
 *        text={text}
 *        matchedSubstrings={matchedSubstrings}
 *      />
 *    );
 *
 *    // <strong>Alexa</strong>nderstraße <strong>5</strong>, Berlin, Germany
 */
import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  text: PropTypes.string.isRequired,
  matchedSubstrings: PropTypes.arrayOf(
    PropTypes.shape({
      offset: PropTypes.number.isRequired,
      length: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

function TextWithMatchedSubstrings({ text, matchedSubstrings }) {
  // E.g. [<strong>Alexa</strong>, 'nderstraße ', <strong>5</strong>, 'Berlin, Germany']
  const fragmentsToRender = [];

  for (let i = 0; i < matchedSubstrings.length; i++) {
    const match = matchedSubstrings[i];
    if (i === 0 && match.offset > 0) {
      const substr = text.slice(0, match.offset);

      fragmentsToRender.push(
        <React.Fragment key={fragmentsToRender.length}>
          {substr}
        </React.Fragment>,
      );
    }

    const substr = text.slice(match.offset, match.offset + match.length);
    fragmentsToRender.push(
      <strong key={fragmentsToRender.length}>{substr}</strong>,
    );
    const nextMatch = matchedSubstrings[i + 1];
    if (nextMatch && nextMatch.offset > match.offset + match.length) {
      const substr = text.slice(match.offset + match.length, nextMatch.offset);
      fragmentsToRender.push(
        <React.Fragment key={fragmentsToRender.length}>
          {substr}
        </React.Fragment>,
      );
    } else if (!nextMatch && match.offset + match.length < text.length) {
      const substr = text.slice(match.offset + match.length);
      fragmentsToRender.push(
        <React.Fragment key={fragmentsToRender.length}>
          {substr}
        </React.Fragment>,
      );
    }
  }

  return fragmentsToRender;
}

TextWithMatchedSubstrings.propTypes = propTypes;

export default TextWithMatchedSubstrings;
