/* eslint-disable react/prop-types */
import React from "react";
import GeocodingInputSearch from "./GeocodingInputSearch";
import GeocodingInputSearchMobile from "./GeocodingInputSearchMobile";

import "./GeocodingSearch.scss";

const GeocodingSearch = (props) => (
  <div className="GeocodingSearch">
    <GeocodingInputSearchMobile
      dataTestId="GeocodingInputSearchMobileId"
      {...props}
    />
    <GeocodingInputSearch animateWidth showInputIcon {...props} />
  </div>
);

export default GeocodingSearch;
