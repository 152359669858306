import React from "react";
import PropTypes from "prop-types";
import useScript, {
  ScriptLoadingStrategy,
} from "../../../../../hooks/useScript";

const TrustpilotReviews = ({ t, lang = "en" }) => {
  const status = useScript({
    src: "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
    scriptLoadingStrategy: ScriptLoadingStrategy.DEFER,
  });

  if (status === "error") {
    // Hide the component completely if the script could not be loaded
    // Which happens if the user has an adblocker enabled
    return null;
  }

  return (
    <React.Fragment>
      <h2 className="TrustpilotReviews-header-headline">
        {t("spages.homepage.trustpilot.headline")}
      </h2>
      <div className="TrustPilotWidget">
        <div
          className="trustpilot-widget"
          data-locale={lang}
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="5b1a3bc8346edc000179e207"
          data-style-width="100%"
          data-theme="light"
          data-stars="5"
          data-review-languages={lang}
          data-font-family="Poppins"
        >
          <a
            href="https://www.trustpilot.com/review/wunderflats.com"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

TrustpilotReviews.propTypes = {
  t: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default TrustpilotReviews;
