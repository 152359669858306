import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  fillPathColor: PropTypes.string,
};

const CloseArrowIcon = ({ fillPathColor = "#24272E", ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 4.49998L11.5 3.09998L8 6.59998L4.5 3.09998L3 4.49998L6.6 7.99998L3.1 11.5L4.5 12.9L8 9.39998L11.5 12.9L12.9 11.5L9.4 7.99998L13 4.49998Z"
        fill={fillPathColor}
      />
    </svg>
  );
};

CloseArrowIcon.propTypes = propTypes;

export default CloseArrowIcon;
