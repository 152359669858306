import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import DateMonthView from "../DateMonthView/DateMonthView";

class DateWidget extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    t: PropTypes.func.isRequired,
    mobileLabel: PropTypes.string,
    renderDay: PropTypes.func,
    onSelect: PropTypes.func,
    selected: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    isDateAvailable: PropTypes.func,
    setAttributes: PropTypes.func,
    onHoverDay: PropTypes.func,
    clearButtonText: PropTypes.node,
  };

  constructor(props) {
    super();
    this.state = this.stateFromProps(props);
    this.viewPreviousMonth = this.viewPreviousMonth.bind(this);
    this.viewNextMonth = this.viewNextMonth.bind(this);
  }

  // TODO: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(props) {
    this.setState(this.stateFromProps(props, this.props));
  }

  stateFromProps(props, prevProps = {}) {
    const selectedDateChanged = !!(
      props.selected && props.selected !== prevProps.selected
    );
    const minDateChanged = !!(
      props.minDate && props.minDate !== prevProps.minDate
    );
    const currentDefaultMonth = this.state && this.state.defaultMonth;

    let nextDefaultMonth = currentDefaultMonth || dayjs.utc().startOf("month");

    if (selectedDateChanged) {
      nextDefaultMonth = dayjs.utc(props.selected).startOf("month");
    } else if (minDateChanged) {
      nextDefaultMonth = dayjs.utc(props.minDate).startOf("month");
    }

    return {
      minDate: props.minDate && dayjs.utc(props.minDate),
      maxDate: props.maxDate && dayjs.utc(props.maxDate),
      defaultMonth: nextDefaultMonth,
    };
  }

  viewPreviousMonth() {
    const defaultMonth = this.state.defaultMonth.clone();
    this.setState({ defaultMonth: defaultMonth.subtract(1, "month") });
  }

  viewNextMonth() {
    const defaultMonth = this.state.defaultMonth.clone();

    this.setState({ defaultMonth: defaultMonth.add(1, "month") });
  }

  clearDate = () => {
    this.props.onSelect("");
  };

  render() {
    const { t, clearButtonText, mobileLabel, name } = this.props;
    const { defaultMonth, minDate, maxDate } = this.state;
    const endOfMonth = defaultMonth.clone().endOf("month");
    const backEnabled = !minDate || minDate.isBefore(defaultMonth);
    const nextEnabled = !maxDate || maxDate.isAfter(endOfMonth);

    return (
      <div data-testid={`DateWidget-${name}`} className="DateWidget">
        {mobileLabel && (
          <div className="DateWidget-mobileLabel">{mobileLabel}</div>
        )}
        <div className="DateWidget-monthNavigation">
          <button
            type="button"
            disabled={!backEnabled}
            onClick={this.viewPreviousMonth}
            className="DateWidget-viewPreviousMonth"
          />
          <div className="DateWidget-currentMonth">
            {defaultMonth.format("MMMM YYYY")}
          </div>
          <button
            type="button"
            disabled={!nextEnabled}
            onClick={this.viewNextMonth}
            className="DateWidget-viewNextMonth"
          />
        </div>
        <DateMonthView
          defaultMonth={this.state.defaultMonth.format("YYYY-MM-DD")}
          renderDay={this.props.renderDay}
          selected={this.props.selected}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          onSelect={this.props.onSelect}
          isDateAvailable={this.props.isDateAvailable}
          setAttributes={this.props.setAttributes}
          onHoverDay={this.props.onHoverDay}
          t={t}
        />
        <button
          type="button"
          className="DateWidget-clearButton"
          data-testid="DateWidget-clearButton"
          onClick={this.clearDate}
        >
          {clearButtonText || t("components.DateWidget.clear")}
        </button>
      </div>
    );
  }
}

export default DateWidget;
