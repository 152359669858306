// Only for the ListingsPage and Collections / Categories page

import { urlMaker } from "./url";
const VALID_ROUTE_NAMES = ["listinglist", "categories"];

const generateUrls = ({
  language,
  regionSlug,
  categorySlug,
  query,
  page = 1,
  totalPages,
  routeName = "listinglist",
}) => {
  if (!VALID_ROUTE_NAMES.includes(routeName)) {
    throw new Error(
      `${routeName} is not among the VALID_ROUTE_NAMES: ${JSON.stringify(
        VALID_ROUTE_NAMES,
      )}`,
    );
  }

  let prevUrl = null;
  let nextUrl = null;
  const url = urlMaker(language, { fullyQualified: true });

  if (page > 1) {
    prevUrl = url(routeName, {
      region: regionSlug,
      category: categorySlug,
      page: page === 2 ? undefined : page - 1,
    });
    if (query) {
      prevUrl = `${prevUrl}${query}`;
    }
  }

  if (page < totalPages) {
    nextUrl = url(routeName, {
      region: regionSlug,
      category: categorySlug,
      page: page + 1,
    });
    if (query) {
      nextUrl = `${nextUrl}${query}`;
    }
  }

  return {
    prev: prevUrl,
    next: nextUrl,
    canonical: url(routeName, {
      region: regionSlug,
      category: categorySlug,
      page: page === 1 ? undefined : page,
    }),
  };
};

const utils = { generateUrls };

export default utils;
