const checkEmailConfirmationParams = ({
  t,
  location,
  query,
  toaster,
  history,
}) => {
  const { emailConfirmed, emailConfirmationErrorCode } = query;

  if (emailConfirmationErrorCode) {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("emailConfirmationErrorCode");
    history.replace({ search: queryParams.toString() });

    toaster.basicToaster.add({
      text: t("spages.homepage.emailChangeConfirmation.errors.generic"),
      timeout: 15000,
      intent: "danger",
    });
  }

  if (emailConfirmed) {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("emailConfirmed");
    history.replace({ search: queryParams.toString() });

    toaster.basicToaster.add({
      text: t("spages.homepage.userEmailConfirmationSuccess"),
      timeout: 5000,
      intent: "success",
    });
  }
};

export default checkEmailConfirmationParams;
