import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const propTypes = {
  src: PropTypes.string.isRequired,
  sizes: PropTypes.string,
  srcSet: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.oneOf(["eager", "lazy"]),
};

const Img = React.forwardRef(
  (
    { src, sizes, srcSet, alt = "", className, loading = "lazy", ...props },
    ref,
  ) => {
    return (
      <img
        ref={ref}
        alt={alt}
        loading={loading}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
        className={classnames(className)}
        {...props}
      />
    );
  },
);

Img.propTypes = propTypes;

export default Img;
