import "./HomePage.scss";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import Accordion from "../../../../components/Accordion/Accordion";
import AppHeader from "../../../../components/AppHeader/AppHeader";
import Footer from "../../../../components/Footer/Footer";
import UkrainianFlag from "../../../../components/Icons/Flags/Ukraine";
import LocalizedLink from "../../../../components/LocalizedLink/LocalizedLink";
import toaster from "../../../../components/Toaster/Toaster";
import spaPagePropTypes from "../../../../types/SpaPage/SpaPagePropTypes";
import dataLayer from "../../../../utils/data-layer";
import { canUseDOM } from "../../../../utils/dom";
import { safeDecodeComponent, urlMaker } from "../../../../utils/url";
import PageHead from "../../PageHead";
import checkEmailConfirmationParams from "./checkEmailConfirmationParams";
import Cities from "./Cities/Cities";
import Collections from "./Collections/Collections";
import HomePageBanner from "./HomePageBanner/HomePageBanner";
import InfoSection from "./InfoSection/InfoSection";
import LandlordSection from "./LandlordSection/LandlordSection";
import SearchBar from "./SearchBar/SearchBar";
import TrustpilotReviews from "./TrustpilotReviews/TrustpilotReviews";

const propTypes = {
  ...spaPagePropTypes,
  pageData: PropTypes.shape({
    homePageFaq: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        answer: PropTypes.string,
      }),
    ),
    defaultFilters: PropTypes.object,
  }),
};

const parseQuestions = (questions) =>
  questions.map((question, key) => (
    <Accordion.Section title={question.question} key={key}>
      <Accordion.Content>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: question.answer }}
        />
      </Accordion.Content>
    </Accordion.Section>
  ));

function HomePage({
  currentUrl,
  lang,
  pageData,
  query,
  t,
  translationUrls,
  url,
  user,
  analytics,
  routerLoading = false,
}) {
  const { homePageFaq } = pageData;
  const history = useHistory();
  const location = useLocation();
  const headline = t("spages.homepage.FAQ.headline");
  const viewMoreText = t("spages.homepage.FAQ.viewAll");
  const regions = pageData.regions.items.map((region) => ({
    ...region,
    slug: region.slug,
    label: t(`regions.${region.slug}`),
  }));

  const filterCity = safeDecodeComponent(pageData.defaultFilters?.city);
  const defaultCity =
    regions.filter(({ label }) => label.toLowerCase() === filterCity)[0]
      ?.slug || "berlin";

  const canonicalUrl = urlMaker(lang, { fullyQualified: true })("home");
  const headProps = {
    title: t("components.HomePage.documentTitle"),
    description: t("components.HomePage.metaDescription"),
    canonicalUrl,
    translationUrls,
    lang,
  };

  useEffect(() => {
    if (!routerLoading) {
      dataLayer.pushToDataLayer(dataLayer.initialDataLayer("home", { user }));
    }
  }, [routerLoading, user]);

  useEffect(() => {
    checkEmailConfirmationParams({ history, location, query, t, toaster });

    // Email should only be checked once, on page's first load.
    // DANGER: don't add history or location to dependency array because checkEmailConfirmationParams
    // function changes them and it will result in an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This useEffect controls the animation of the header color change as the user
   * scrolls down the page.
   */
  const appHeaderRef = useRef(null);
  const splashRef = useRef(null);
  useEffect(() => {
    const isIntersectionObserverSupported =
      canUseDOM() && "IntersectionObserver" in window;
    if (!isIntersectionObserverSupported) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        appHeaderRef.current.classList.add("AppHeader-theme--brandGradient");
        appHeaderRef.current.classList.remove("AppHeader-theme--white");
      } else {
        appHeaderRef.current.classList.remove("AppHeader-theme--brandGradient");
        appHeaderRef.current.classList.add("AppHeader-theme--white");
      }
    });

    if (splashRef.current) {
      observer.observe(splashRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <article className="HomePage">
      <PageHead {...headProps} />

      <AppHeader
        currentUrl={currentUrl}
        lang={lang}
        t={t}
        ref={appHeaderRef}
        theme="brandGradient"
        className="Homepage-AppHeader"
        translationUrls={translationUrls}
        url={url}
        user={user}
        analyticsEvent={analytics?.event}
      />

      <section className="HomepageSplash" ref={splashRef}>
        <div className="Homepage-Search">
          <div className="Homepage-Search-inner">
            <h1 className="HomePage-headline">
              {t("spages.homepage.headline")}
            </h1>
            <h2 className="HomePage-subheadline">
              {t("spages.homepage.subheadline")}
            </h2>
            <SearchBar
              defaultFilters={pageData.defaultFilters}
              lang={lang}
              regions={regions}
              t={t}
              url={url}
              analyticsEvent={analytics?.event}
            />
          </div>
        </div>
        <LocalizedLink
          className="HomePage_banner_link"
          linkKey="ukraine-home"
          data-testid="ukraineHomeSmallBannerLink"
        >
          <HomePageBanner
            icon={
              <div className="HomePage_ukraine-flag">
                <UkrainianFlag
                  width={20}
                  height={20}
                  className="HomePage_ukraineButton_svg"
                />
              </div>
            }
          >
            <HomePageBanner.PrimaryText>
              {t("spages.homepage.banner.supportUkrainians")}
            </HomePageBanner.PrimaryText>
            <HomePageBanner.SecondaryText>
              {t("spages.homepage.banner.supportUkrainiansClickToLearnMore")}
            </HomePageBanner.SecondaryText>
          </HomePageBanner>
        </LocalizedLink>
      </section>

      {pageData?.categories?.length > 0 && (
        <section className="ContentContainer ContentContainer--fullBleed Homepage-CollectionsSection">
          <Collections
            t={t}
            url={url}
            categories={pageData.categories}
            query={query}
            lang={lang}
            regionSlug={defaultCity}
            analytics={analytics}
            defaultFilters={pageData.defaultFilters}
          />
        </section>
      )}
      <section className="Homepage-bottomGrayGradient Homepage-InfoSection">
        <div className="ContentContainer">
          <InfoSection t={t} />
        </div>
      </section>
      <section className="ContentContainer ContentContainer--fullBleed Homepage-CitiesSection">
        <Cities t={t} url={url} analytics={analytics} />
      </section>

      {homePageFaq.length > 0 ? (
        <section className="ContentContainer Homepage-FAQSection">
          <Accordion
            headline={headline}
            viewMore={{
              text: viewMoreText,
              url: url("help"),
            }}
          >
            {parseQuestions(homePageFaq)}
          </Accordion>
        </section>
      ) : null}
      <section className="ContentContainer Homepage-TrustpilotSection">
        <TrustpilotReviews t={t} lang={lang} />
      </section>
      <section className="Homepage-grayBackground Homepage-LandlordSection">
        <div className="ContentContainer">
          <LandlordSection t={t} url={url} lang={lang} />
        </div>
      </section>
      <Footer url={url} regions={pageData.regions.items} user={user} />
    </article>
  );
}

HomePage.propTypes = propTypes;

export default HomePage;
