export default ({ listing, language }) => ({
  "@context": "https://schema.org",
  "@type": "Apartment",
  name: listing.title[language],
  numberOfRooms: listing.rooms,
  occupancy: listing.accommodates,
  floorSize: listing.area,
  image:
    listing.coverImage?.urls?.original ||
    listing.pictures?.[0]?.urls?.original ||
    "/public/images/listing-bg.jpg",
  address: {
    "@type": "PostalAddress",
    addressLocality: listing.address.city,
    streetAddress: listing.address.street,
  },
});
